import * as React from 'react';
import { useParams } from "react-router-dom";

import {
	Avatar,
	Box,
	Chip,
	FormControl,
	Grid,
	MenuItem,
	Select,
	Tabs,
	Tab,
	TextField,
	Tooltip,
	Typography,
} from "@mui/material";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import { IconButton } from '@mui/material';

import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import { CreateMap } from '../components/create-map/create-map.js';
import { CreateMission } from '../components/create-mission/create-mission.js';
import CreateEnvironment from '../components/create-environment/index.js';
import Testbed from './Testbed.js';

import CodeMirror from '@uiw/react-codemirror';
import CodeMirrorMerge from 'react-codemirror-merge';
import { javascript } from '@codemirror/lang-javascript';
import { python } from '@codemirror/lang-python';

import ReactJson from 'react-json-view';

import { EditorView } from 'codemirror';
import { EditorState } from '@codemirror/state';
import { linter, lintGutter } from "@codemirror/lint";
import MDEditor from "@uiw/react-md-editor";

import TaskAltIcon from '@mui/icons-material/TaskAlt';
import ErrorIcon from '@mui/icons-material/Error';
import CompareIcon from '@mui/icons-material/Compare';
import HistoryIcon from '@mui/icons-material/History';
import SmsFailedIcon from '@mui/icons-material/SmsFailed';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import VisibilityIcon from "@mui/icons-material/Visibility";
import CodeIcon from '@mui/icons-material/Code';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteIcon from '@mui/icons-material/Delete';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';

import { memo, useCallback, useEffect, useState } from "react";

import { useDispatch, useSelector } from 'react-redux';
import { addBroker, setBrokers, deleteBroker, updateBroker } from "../components/AppCreator/slice-brokers.js";
import { setVariables } from "../components/AppCreator/slice-storage-variables.js";
import { setThreads } from "../components/AppCreator/slice-threads.js";
import { setNodesParameters } from "../components/AppCreator/slice-nodes.js";
import { setErrors, cleanErrors } from "../components/AppCreator/slice-errors.js";

import Spinner from "../components/Spinner.js";
import { useSnackbar, customHighlighter, setCustomViews, jwt } from "../utils/index.js";
import AreYouSurePopup from "../components/Popups/AreYouSurePopup.js";
import Dialog from "../components/Dialog.js";
import Popup from "../components/Popup.js";
import Form from "../components/Form.js";
import LlmIcon from "../../src/assets/icons/llm.png";

import { countLines } from "../utils/locsys-constants.js";
import { getDslConstants, getDslsImages, getDslsNames } from '../dsls/index.js';

import {
	getModel,
	validateModel,
	saveModel,
	saveModelSubtype,
	generateCode,
	removeModel,
	deployModel,
	importToModel,
	transformModel,
	getUsersModels,
	promptModel,
	clearModelPrompts,
	editModelMetadata,
} from '../api/index.js';

const useStyles = makeStyles({
	buttonCursor: {
		cursor: "pointer",
	},
	select: {
		color: "orange", // Set the desired color for the select
		"&:before": {
			borderColor: "orange", // Set the desired color for the select's border
		},
		"&:after": {
			borderColor: "orange", // Set the desired color for the select's border when focused
		},
	},
	selectSmall: {
		color: "orange", // Set the desired color for the select
		"&:before": {
			borderColor: "orange", // Set the desired color for the select's border
		},
		"&:after": {
			borderColor: "orange", // Set the desired color for the select's border when focused
		},
		fontSize: "0.8rem",
	},
	boldText: {
		fontWeight: 'bold',
	},
	clickableChip: {
		cursor: 'pointer',
	},
	denseListItem: {
		paddingTop: 0.5,
		paddingBottom: 0.5,
	},
});

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
	({ theme }) => ({
		textTransform: 'none',
		fontWeight: theme.typography.fontWeightRegular,
		fontSize: theme.typography.pxToRem(15),
		marginRight: theme.spacing(1),
		color: 'rgba(255, 255, 255, 0.7)',
		'&.Mui-selected': {
			color: '#fff',
		},
	}),
);

const DslModelScreen = (params) => {
	document.documentElement.dataset.colorMode = 'light';
	const { success, error } = useSnackbar();
	// eslint-disable-next-line no-unused-vars
	const [currentDsl, setCurrentDsl] = useState(params.dsl);
	const [user, setUser] = useState(jwt.decode());
	const [isLoading, setIsLoading] = useState(false);
	const [documentation, setDocumentation] = useState("");
	const [languageLogo, setLanguageLogo] = useState("");
	const [model, setModel] = useState(null);
	const [value, setValue] = React.useState("");
	const [saved, setSaved] = React.useState(true);
	const { modelid } = useParams();
	const [comparisonMode, setComparisonMode] = useState(false);
	const [oldValue, setOldValue] = useState("");
	const [selectedValidation, setSelectedValidation] = useState(null);
	const [autoSaveTimerId, setAutoSaveTimerId] = useState(null);
	const [insertables, setInsertables] = useState([]);
	const [actions, setActions] = useState([]);
	const [modelToRevert, setModelToRevert] = useState(null);
	const [revertModelPopupOpen, setRevertModelPopupOpen] = useState(false);
	const [validationsPopupOpen, setValidationsPopupOpen] = useState(false);
	const [subtypes, setSubtypes] = useState([]);
	const [selectedSubtype, setSelectedSubtype] = useState(null);
	const [dslConstants, setDslConstants] = useState({});
	const [generationTypes, setGenerationTypes] = useState([]);
	const [selectedGeneration, setSelectedGeneration] = useState(null);
	const [supportsValidation, setSupportsValidation] = useState(true);
	const [dslsImages, setDslsImages] = useState({});
	const [dslsNames, setDslsNames] = useState({});
	const [canTransformTo, setCanTransformTo] = useState([]);
	const [mustImport, setMustImport] = useState([]);
	const [canImport, setCanImport] = useState([]);
	const [hasRelationToOtherDsls, setHasRelationToOtherDsls] = useState(false);
	const [deleteModelPopupOpen, setDeleteModelPopupOpen] = useState(false);
	const [generatedCode, setGeneratedCode] = useState(null);
	const [generationLanguage, setGenerationLanguage] = useState(null);
	const [codemirrorGeneratedLanguage, setCodemirrorGeneratedLanguage] = useState(null);
	const [deploymentInformation, setDeploymentInformation] = useState(null);
	const [supportsGeneration, setSupportsGeneration] = useState(false);
	const [supportsDeployment, setSupportsDeployment] = useState(false);
	const [supportsLLM, setSupportsLLM] = useState(false);
	const [hasChanged, setHasChanged] = useState(false);
	const [highlightedLines, setHighlightedLines] = useState([]);
	const [autosave, setAutosave] = useState(true);

	const [errorToShow, setErrorToShow] = useState(null);
	const codeMirrorRef = React.useRef(null);
	const codeMirrorGenerationRef = React.useRef(null);

	// App maker stuff here
	const dispatch = useDispatch();
	const storeVariables = useSelector((state) => state.storageVariables);
	const storeBrokers = useSelector((state) => state.brokers);
	const [addBrokerPopupOpen, setAddBrokerPopupOpen] = useState(false);
	const [brokerToEdit, setBrokerToEdit] = useState({});
	const storeLogs = useSelector((state) => state.logs);
	const validationErrors = useSelector((state) => state.errors);
	const [parsedVariables, setParsedVariables] = useState([]);

	// Handle import models
	const [importModelPopupOpen, setImportModelPopupOpen] = useState(false);
	const [selectedModelToImport, setSelectedModelToImport] = useState(null);
	const [relatedModels, setRelatedModels] = useState([]);

	// Handle transformation
	const [dslToTransform, setDslToTransform] = useState(null);
	const [addModelPopupOpen, setAddModelPopupOpen] = useState(false);

	const [editModelPopupOpen, setEditModelPopupOpen] = useState(false);

	// Handle llm prompt
	const [promptPopupOpen, setPromptPopupOpen] = useState(false);

	const [tabValue, setTabValue] = React.useState(0);
	const handleTabChange = (event, newValue) => {
		setTabValue(newValue);
	};

	const [open, setOpen] = React.useState(false);
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);

	const classes = useStyles();
	// const navigate = useNavigate();

	const fetchData = useCallback(
		async () => {
			setIsLoading(true);
			const constants = await getDslConstants(currentDsl);
			if (constants.type === "Textual") {
				await setCustomViews(currentDsl);
			}

			setDslConstants(constants);
			setAutosave(constants.autosave ?? true);
			setDocumentation(constants.documentation);
			setInsertables(constants.insertableEntities);
			setSubtypes(constants.subtypes);
			setLanguageLogo(constants.logoUrl);
			setSupportsValidation(constants.hasValidation);
			setSupportsLLM(constants.llmSupport);
			setCanTransformTo(constants.canTransformTo);
			setMustImport(constants.mustImport);
			setCanImport(constants.canImport);
			const relation = constants.canTransformTo.length > 0
				|| constants.mustImport.length > 0
				|| constants.canImport.length > 0;
			setHasRelationToOtherDsls(relation);
			setGenerationTypes(constants.generations);
			if (selectedGeneration === null) {
				setSelectedGeneration(constants.generations.at(0));
			}

			setGenerationLanguage(constants.generationLanguage);
			setSupportsGeneration(constants.supportsGeneration);
			setSupportsDeployment(constants.supportsDeployment);
			switch (constants.generationLanguage) {
				case "javascript": {
					setCodemirrorGeneratedLanguage(javascript({ jsx: true }));
					break;
				}

				case "python": {
					setCodemirrorGeneratedLanguage(python());
					break;
				}

				default: {
					setCodemirrorGeneratedLanguage(null);
					break;
				}
			}

			const _dslsImages = await getDslsImages(true);
			setDslsImages(_dslsImages);
			const _dslsNames = await getDslsNames();
			setDslsNames(_dslsNames);

			const tmpActions = [];
			// Add insertables in actions
			const keys = Object.keys(constants.insertableEntities);
			for (const key of keys) {
				tmpActions.push({ icon: <NoteAddIcon />, name: key });
			}

			setActions(tmpActions);

			// Fetch the model here
			try {
				const { success: scs, model: mo } = await getModel(modelid);
				if (scs) {
					setModel(mo);
					setValue(mo.model_text);
					setSelectedSubtype(mo.model_subtype);
					console.log("DSLModelScreen: Current model", mo);

					if (constants.mustImport.length > 0 && mo.finalImports.length === 0) {
						error("This model must import models from other DSLs first");
					}
				}
			} catch {
				console.error(error);
			}

			setSaved(true);
			setIsLoading(false);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[error, modelid],
	);

	useEffect(() => {
		console.log("Variables from store", storeVariables);
		// Keep only name and value from variables
		const parsedDict = {};
		// eslint-disable-next-line array-callback-return
		storeVariables.map((v) => {
			const _n = v.variable ?? "undefined";
			const _v = v.value ?? "undefined";
			parsedDict[_n] = _v;
		});
		setParsedVariables(parsedDict);
		console.log("Parsed dict:", parsedDict);
	}, [storeVariables]);

	const highlightLines = (lineNumber, responseError) => {
		setHighlightedLines([{ line: lineNumber, message: responseError }]);
	};

	const handleGetUserModels = (_dsl) => {
		(async () => {
			try {
				const { success: scs, models } = await getUsersModels(user.id, _dsl, true);
				console.log("Models", models);
				if (scs) {
					setRelatedModels(models);
					if (models.length === 0) {
						error("No models found");
						return;
					}

					setSelectedModelToImport(models[0]._id);
					setImportModelPopupOpen(true);
				}
			} catch {
				console.error(error);
			}
		})();
	};

	const saveModelText = async () => {
		if (model) {
			try {
				const { success: scs } = await saveModel(model._id, value);
				if (scs) {
					setSaved(true);
				} else {
					error("Failed to save model");
				}
			} catch {
				console.error(error);
			}
		}
	};

	const handleTransformToModel = async (values) => {
		setIsLoading(true);
		console.log("Transform to", dslToTransform);
		try {
			await saveModelText();
			const { success: scs, newModel, message } = await transformModel(
				model._id,
				dslToTransform,
				values.title,
				values.description,
			);
			if (scs) {
				success("Model imported successfully");
				console.log('New model', newModel);
				// Go to the new model
				window.open(`/dsls/${newModel.model_type}/${newModel._id}`, "_self");
			} else {
				error(message);
			}
		} catch {
			console.error(error);
		}

		setAddModelPopupOpen(false);
		setDslToTransform(null);
		setIsLoading(false);
	};

	const handleEditModel = async (values) => {
		setIsLoading(true);
		console.log("Edit model", values);
		try {
			const { success: scs, newModel, message } = await editModelMetadata(
				model._id,
				values.title,
				values.description,
			);
			if (scs) {
				success("Model edited successfully");
			} else {
				error(message);
			}
		} catch {
			console.error(error);
		}

		await fetchData();
		setEditModelPopupOpen(false);
		setIsLoading(false);
	};

	const handleAddBroker = async () => {
		setIsLoading(true);

		const broker = {
			id: brokerToEdit.id ?? Math.random().toString(),
			name: brokerToEdit.name,
			host: brokerToEdit.host,
			port: brokerToEdit.port,
			username: brokerToEdit.username,
			password: brokerToEdit.password,
		};

		dispatch(updateBroker(broker));

		setBrokerToEdit({});
		setAddBrokerPopupOpen(false);
		setIsLoading(false);
	};

	const handleImportModelToModel = async () => {
		setIsLoading(true);
		console.log("Imported model", selectedModelToImport);
		try {
			const { success: scs } = await importToModel(model._id, selectedModelToImport);
			if (scs) {
				success("Model imported successfully");
			} else {
				error("Failed to import model");
			}
		} catch {
			console.error(error);
		}

		// await fetchData();
		// Refresh the page
		window.location.reload();
		setImportModelPopupOpen(false);
		setIsLoading(false);
	};

	const handleAddText = (newText) => {
		const cursor = codeMirrorRef.current.view.state.selection.main.head;
		const transaction = codeMirrorRef.current.view.state.update({
			changes: {
				from: cursor,
				insert: newText,
			},
			selection: { anchor: cursor + 1 },
			scrollIntoView: true,
		});

		if (transaction) {
			codeMirrorRef.current.view.dispatch(transaction);
		}
	};

	useEffect(() => {
		if (model?.model_text !== value) {
			setSaved(false);
		}

		if (autosave === true) {
			// stop previous save timer if exists
			if (autoSaveTimerId) {
				clearTimeout(autoSaveTimerId);
			}

			// set new auto save timer
			const newTimeout = setTimeout(saveModelText, 2000);
			setAutoSaveTimerId(newTimeout);
		}

		setHasChanged(true);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [value]);

	// eslint-disable-next-line no-unused-vars
	const onChange = useCallback((val, viewUpdate) => {
		setValue(val);
		setHasChanged(true);
	}, []);

	// eslint-disable-next-line no-unused-vars
	const [screenSize, setScreenSize] = useState({
		width: window.innerWidth,
		height: window.innerHeight,
	});

	const Original = CodeMirrorMerge.Original;
	const Modified = CodeMirrorMerge.Modified;

	const handlePromptModel = async (values) => {
		setIsLoading(true);
		console.log("Prompt:", values.prompt);
		try {
			const { success: scs, result, message } = await promptModel(model._id, values.prompt, values.useModel);
			if (scs) {
				success("Model prompted successfully");
				console.log("Prompt result", result);
			} else {
				error(message);
			}
		} catch {
			console.error(error);
		}

		await fetchData();
		setPromptPopupOpen(false);
	};

	const handleClearPrompts = async () => {
		setIsLoading(true);
		try {
			const { success: scs, message } = await clearModelPrompts(model._id);
			if (scs) {
				success("Prompts cleared successfully");
			} else {
				error(message);
			}
		} catch {
			console.error(error);
		}

		await fetchData();
		setIsLoading(false);
	};

	useEffect(() => {
		(async () => {
			console.log("First time loading model, cleaning store");
			dispatch(setVariables([]));
			dispatch(setThreads([]));
			dispatch(setBrokers([]));
			dispatch(setNodesParameters([]));
			// Fetching data
			await fetchData();
		})();
	}, [dispatch, fetchData]);

	const saveSubtype = async (subt) => {
		setIsLoading(true);
		if (model) {
			try {
				const { success: scs } = await saveModelSubtype(model._id, subt);
				if (scs) {
					setSaved(true);
				} else {
					error("Failed to save subtype");
				}
			} catch {
				console.error(error);
			}
		}

		await fetchData();
		setIsLoading(false);
	};

	const validateModelInternal = async () => {
		if (dslConstants.hasValidation === false) {
			error("This DSL does not support validation");
			return;
		}

		// For appcreator models, we use a different validation method
		dispatch(cleanErrors());

		setIsLoading(true);
		try {
			if ((model.model_subtype === null || model.model_subtype === undefined) && subtypes.length > 0) {
				error("Please select a subtype first");
				setIsLoading(false);
				return;
			}

			// Save before the validation
			await saveModelText();

			const res = await validateModel(
				model._id,
				value,
			);

			if (model?.model_type === "appcreator") {
				setIsLoading(false);
				if (res.success === true) {
					setHasChanged(false);
				} else {
					const information = JSON.parse(res.validationResponse);
					console.log("Validation response", information);
					dispatch(setErrors(information.errors));
				}

				return;
			}

			if (res.success === false) {
				const pattern = /\b(\d+):(\d+):/;
				const match = pattern.exec(res.validationResponse);
				const responseError = res?.validationResponse?.split(pattern)?.pop() || "Validation error";
				if (match) {
					// Extract the line number
					const lineNumber = Number.parseInt(match[1], 10);
					highlightLines(lineNumber, responseError);
					error(`Error found in line ${lineNumber}`);
				} else {
					console.log("Line number not found in the error message.");
				}
			} else {
				success("Model validated successfully");
				setHighlightedLines([]);
				setHasChanged(false);
			}

			setTabValue(0);
		} catch { /* empty */ }

		await fetchData();
		setIsLoading(false);
	};

	const revertModel = () => {
		setIsLoading(true);

		setValue(modelToRevert);

		setIsLoading(false);
		setRevertModelPopupOpen(false);
		setComparisonMode(false);
		setModelToRevert(null);
	};

	const declineRevertModel = () => {
		setRevertModelPopupOpen(false);
		setModelToRevert(null);
	};

	const handleGenerateCode = async (validationId) => {
		setIsLoading(true);
		if (model) {
			try {
				const { success: scs, message: msg, code: cod, filename: fname, filetype: ftype } = await generateCode(
					model._id,
					validationId,
					selectedGeneration,
					dslConstants.generationsOutputMap[selectedGeneration],
				);
				if (scs) {
					success(`${selectedGeneration} code generated successfully!`);
					if (cod !== null && cod !== "" && cod !== undefined) {
						setDeploymentInformation(null);
						setTabValue(2);
						if (dslConstants.generationsOutputMap[selectedGeneration] === "file") {
							// Check if language is dflow
							if (model.model_type === "dflow") {
								setDeploymentInformation("### Deployment instructions\n\nPlease follow the instructions in the following link	to deploy and talk to your assistant: <a href=\"https://colab.research.google.com/gist/etsardou/df606136e3acd0661eb6feca8abf0aa4/rasa-deployment.ipynb\" target=\"_blank\">Instructions</a>");
								setGeneratedCode("All good! Generated code is provided as a file.");
							} else {
								setGeneratedCode("All good!");
							}

							// download the file
							const byteCharacters = atob(cod);
							const byteNumbers = Array.from({ length: byteCharacters.length });
							for (let i = 0; i < byteCharacters.length; i++) {
								byteNumbers[i] = byteCharacters.codePointAt(i);
							}

							const byteArray = new Uint8Array(byteNumbers);

							const blob = new Blob([byteArray], { type: ftype });
							const url = URL.createObjectURL(blob);
							const a = document.createElement("a");
							a.href = url;
							a.download = `${fname}`;
							document.body.append(a);
							a.click();

							// cleanup
							URL.revokeObjectURL(url);
							a.remove();
						} else {
							setGeneratedCode(cod);
						}
					}
				} else {
					error(msg);
				}
			} catch {
				console.error(error);
			}
		}

		await fetchData();
		setIsLoading(false);
	};

	const handleDeployCode = async (validationId) => {
		if (model?.model_type !== "appcreator") {
			setIsLoading(true);
		}

		if (model) {
			try {
				const resp = await deployModel(model._id, validationId);
				if (resp.success) {
					success("Code deployed successfully!");
					if (resp.message !== null && resp.message !== "" && resp.message !== undefined) {
						setDeploymentInformation(resp.message);
						if (model?.model_type !== "appcreator") {
							setTabValue(2);
						}
					}
				} else {
					error(resp.message);
				}
			} catch {
				console.error(error);
			}
		}

		setIsLoading(false);
	};

	const deleteModel = async () => {
		setIsLoading(true);
		const { success: successCode, message } = await removeModel(model._id);

		if (successCode) {
			success(message);
			window.open(`/dsls/${model.model_type}`, "_self");
		} else {
			error(message);
		}

		setIsLoading(false);
		setDeleteModelPopupOpen(false);
	};

	const declineDeleteModel = () => {
		setDeleteModelPopupOpen(false);
	};

	const newModelFormContent = [
		{
			customType: "wideInput",
			id: "title",
			type: "input",
			multiline: false,
			width: 400,
			placeholder: "Transformed model title",
		},
		{
			customType: "wideInput",
			id: "description",
			type: "input",
			multiline: true,
			minRows: 3,
			width: 400,
			placeholder: "Transformed model description",
		},
		{
			customType: "button",
			id: "submit",
			type: "submit",
			text: "Transform model",
		},
	];

	const editModelFormContent = [
		{
			customType: "wideInput",
			id: "title",
			type: "input",
			multiline: false,
			width: 400,
			placeholder: "Changed model title",
			value: model?.title,
		},
		{
			customType: "wideInput",
			id: "description",
			type: "input",
			multiline: true,
			minRows: 3,
			width: 400,
			placeholder: "Changed model description",
			value: model?.description,
		},
		{
			customType: "button",
			id: "submit",
			type: "submit",
			text: "Submit",
		},
	];

	const promptFormContent = [
		{
			customType: "wideInput",
			id: "prompt",
			type: "input",
			multiline: true,
			minRows: 5,
			width: 400,
			placeholder: "I'm listening...",
		},
		{
			customType: "checkbox",
			id: "useModel",
			type: "checkbox",
			label: "Take existing model under consideration",
			defaultValue: true,
		},
		{
			customType: "button",
			id: "submit",
			type: "submit",
			text: "Ask locsys assistant",
		},
	];

	const customLinter = linter((view) => {
		const diagnostics = [];
		for (const { line, message } of highlightedLines) {
			diagnostics.push({
				from: view.state.doc.line(line).from,
				to: view.state.doc.line(line).to,
				severity: "error",
				message,
			});
		}

		return diagnostics;
	});

	return (
		<>
			<Spinner open={isLoading} />
			<AreYouSurePopup
				open={revertModelPopupOpen}
				title="Revert model?"
				content="Are you sure you want to revert this model? The current version will be lost."
				onDecline={declineRevertModel}
				onAccept={revertModel}
			/>
			<AreYouSurePopup
				open={deleteModelPopupOpen}
				title="Delete model?"
				content="Are you sure you want to delete this model?"
				onDecline={declineDeleteModel}
				onAccept={deleteModel}
			/>
			<Dialog
				open={!!errorToShow}
				title="Validation response"
				text={errorToShow}
				confirmButton="Ok"
				cancelButton="Cancel"
				onConfirm={() => {
					setErrorToShow(null);
				}}
				onClose={() => {
					setErrorToShow(null);
				}}
			/>
			{/* Popup for llm assistant */}
			<Popup
				width="1000px"
				open={promptPopupOpen}
				title="What can I do for you?"
				onClose={() => {
					setPromptPopupOpen(false);
				}}
			>
				<Grid
					container
					display="flex"
					flexDirection="row"
					justifyContent="center"
				>
					<Form
						content={promptFormContent}
						onSubmit={handlePromptModel}
					/>
					{/* Add button to clear the prompts */}
					<Chip
						key={-1}
						label="Clear prompt history"
						color="secondary"
						className={[classes.clickableChip].join(" ")}
						sx={{
							mt: 2,
							cursor: "pointer",
						}}
						onClick={() => {
							handleClearPrompts();
						}}
					/>
					{/* list of previous prompts with their responses */}
					<List
						sx={{
							width: "100%",
							maxHeight: 600,
							overflow: 'auto',
						}}
					>
						{model?.finalPrompts?.slice().reverse().map((p, index) => (
							<ListItem key={Math.random()} className={classes.denseListItem}>
								<ListItemText
									primary={(
										<>
											<Typography
												variant="h6"
												sx={{
													fontWeight: "bold",
													color: "#fff",
													mb: 0.5,
												}}
											>
												{`Prompt ${model.finalPrompts.length - index}`}
											</Typography>
											<Typography
												variant="body2"
												color="secondary"
												sx={{
													fontSize: "1.1rem",
													fontWeight: "bold",
												}}
											>
												{"Input:"}
											</Typography>
											<Typography
												variant="body2"
												color="secondary"
												mb={0.5}
												sx={{
													background: "white",
													color: "#64686a",
													fontSize: "1rem",
													p: 1,
												}}
											>
												{p.prompt}
											</Typography>
										</>
									)}
									secondary={(
										<>
											<Typography
												variant="body2"
												color="secondary"
												sx={{
													fontSize: "1.1rem",
													fontWeight: "bold",
												}}
											>
												{"Output:"}
											</Typography>
											<CodeMirror
												value={p.result}
												options={{
													theme: "material",
													lineNumbers: true,
													readOnly: true,
													mode: "javascript",
												}}
											/>
											<Typography
												variant="caption"
												color="secondary"
											>
												{`Timestamp: ${p.createdAt.replace("T", " ").replace("Z", "")}`}
											</Typography>
										</>
									)}
								/>
							</ListItem>
						))}
					</List>

				</Grid>
			</Popup>
			{/* Popup for edit model */}
			<Popup
				width="800px"
				open={editModelPopupOpen}
				title="Edit model metadata"
				onClose={() => {
					setEditModelPopupOpen(false);
				}}
			>
				<Form
					content={editModelFormContent}
					onSubmit={handleEditModel}
				/>
			</Popup>
			{/* Popup for transformed model */}
			<Popup
				width="800px"
				open={addModelPopupOpen}
				title="Create new model by transformation"
				onClose={() => {
					setAddModelPopupOpen(false);
				}}
			>
				<Form
					content={newModelFormContent}
					onSubmit={handleTransformToModel}
				/>
			</Popup>
			{/* Popup for new broker */}
			<Popup
				width="800px"
				open={addBrokerPopupOpen}
				title="Add new broker"
				onClose={() => {
					setAddBrokerPopupOpen(false);
				}}
			>
				{/* Add text input to submit name */}
				<TextField
					label="Id"
					variant="filled"
					sx={{
						width: "100%",
						mb: 2,
						display: "none",
					}}
					value={brokerToEdit?.id || ""}
				/>
				<TextField
					label="Name"
					variant="filled"
					sx={{
						width: "100%",
						mb: 2,
					}}
					value={brokerToEdit?.name || ""}
					onChange={(e) => {
						setBrokerToEdit({ ...brokerToEdit, name: e.target.value });
					}}
				/>
				{/* Add text input to submit host */}
				<TextField
					label="Host"
					variant="filled"
					sx={{
						width: "100%",
						mb: 2,
					}}
					value={brokerToEdit?.host || ""}
					onChange={(e) => {
						setBrokerToEdit({ ...brokerToEdit, host: e.target.value });
					}}
				/>
				{/* Add text input to submit port */}
				<TextField
					label="Port"
					variant="filled"
					sx={{
						width: "100%",
						mb: 2,
					}}
					value={brokerToEdit?.port || ""}
					onChange={(e) => {
						setBrokerToEdit({ ...brokerToEdit, port: e.target.value });
					}}
				/>
				{/* Add text input to submit username */}
				<TextField
					label="Username"
					variant="filled"
					sx={{
						width: "100%",
						mb: 2,
					}}
					value={brokerToEdit?.username || ""}
					onChange={(e) => {
						setBrokerToEdit({ ...brokerToEdit, username: e.target.value });
					}}
				/>
				{/* Add text input to submit password */}
				<TextField
					label="Password"
					variant="filled"
					type="password"
					sx={{
						width: "100%",
						mb: 2,
					}}
					value={brokerToEdit?.password || ""}
					onChange={(e) => {
						setBrokerToEdit({ ...brokerToEdit, password: e.target.value });
					}}
				/>
				{/* Add button to submit broker */}
				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "flex-end",
						mt: 2,
					}}
				>
					<Chip
						key={-1}
						label="Submit broker"
						color="secondary"
						className={[classes.clickableChip].join(" ")}
						sx={{
							mr: 0.5,
						}}
						onClick={handleAddBroker}
					/>
				</Box>
			</Popup>
			{/* Create popup with models for the user to select */}
			<Popup
				width="400px"
				open={importModelPopupOpen}
				title="Import model"
				onClose={() => {
					setImportModelPopupOpen(false);
				}}
			>
				{/* Notification for overwrite */}
				<Typography variant="body2" color="white" sx={{ mb: 2 }}>
					{`You are about to import a model into this one. This will overwrite any other imported models of the same type.`}
				</Typography>
				{/* List with models to select */}
				{relatedModels.length > 0 && (
					<FormControl error sx={{ m: 1, width: "100%" }}>
						<Select
							value={selectedModelToImport}
							label="Model"
							sx={{
								backgroundColor: 'gray',
							}}
							size="small"
						>
							{relatedModels.map((m) => (
								<MenuItem key={Math.random()} value={m._id} onClick={() => setSelectedModelToImport(m._id)}>
									{m.title + (m.creator.fullname ? (` by ${m.creator.fullname}`) : "")}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				)}
				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "flex-end",
						mt: 2,
					}}
				>
					<Chip
						key={-1}
						label="Import model"
						color="secondary"
						className={[classes.clickableChip].join(" ")}
						sx={{
							mr: 0.5,
						}}
						onClick={handleImportModelToModel}
					/>
				</Box>
			</Popup>
			{/* Validations popup */}
			<Popup
				width="800px"
				height="500px"
				open={validationsPopupOpen}
				title="Validations"
				onClose={() => {
					setValidationsPopupOpen(false);
				}}
			>
				<Grid
					item
					sx={{
						alignItems: "center",
						flex: 1,
						width: "100%",
					}}
					overflow='auto'
				>
					{/* Validation results and tools here */}
					<Grid
						container
						display="flex"
						flexDirection="row"
						justifyContent="center"
					>
						<Grid
							item
							display="flex"
							flexDirection="row"
							alignItems="center"
							pb={1}
						>
							<List
								// item
								dense
							>
								{model?.finalValidations.map((v, indint) => (
									<ListItem
										key={indint}
										className={classes.denseListItem}
									>
										<ListItemIcon
											sx={{
												ml: 1,
											}}
										>
											{
												v.validated ? <TaskAltIcon style={{ color: "white" }} /> : <ErrorIcon style={{ color: "orange" }} />
											}
										</ListItemIcon>
										<ListItemText>
											<Grid container display="flex" flexDirection="row">
												<Typography
													sx={{
														fontSize: '0.8rem',
														pt: 0.5,
														pr: 2,
														color: v._id === selectedValidation?._id ? "#ff7900" : "#eee",
													}}
												>
													{`Validated on ${v.updatedAt.replace("T", " ").replace("Z", "")}`}
												</Typography>
												<Typography
													sx={{
														fontSize: '0.9rem',
														pt: 0.5,
														pr: 2,
														color: v._id === selectedValidation?._id ? "#ff7900" : "#eee",
													}}
												>
													{`${countLines(v.text)} lines`}
												</Typography>
												<Tooltip title="Compare version">
													<CompareIcon
														color="secondary"
														sx={{
															mr: 2,
														}}
														onClick={() => {
															setComparisonMode(true);
															setSelectedValidation(v);
															setOldValue(v.text);
														}}
													/>
												</Tooltip>
												<Tooltip title="Revert model to current version">
													<HistoryIcon
														color="secondary"
														sx={{
															mr: 2,
														}}
														onClick={() => {
															setModelToRevert(v.text);
															setRevertModelPopupOpen(true);
														}}
													/>
												</Tooltip>
												{v.validated === false && (
													<Tooltip title="Show validation error">
														<SmsFailedIcon
															color="secondary"
															onClick={() => setErrorToShow(v.validation_response)}
														/>
													</Tooltip>
												)}
												{v.validated === true && (
													<Tooltip title="Generate code">
														<CodeIcon
															color="success"
															onClick={() => handleGenerateCode(v._id)}
														/>
													</Tooltip>
												)}
											</Grid>
										</ListItemText>
									</ListItem>
								))}
							</List>
						</Grid>
					</Grid>
				</Grid>
			</Popup>
			<Grid
				container
				item
				mt={2}
				mb={2}
				// width="calc(100vw - 50px)"
				display="flex"
				flexDirection="row"
				justifyContent="space-evenly"
			>

				<Grid
					container
					item
					width="100%"
					xs={9}
					display="flex"
					flexDirection="column"
					sx={{
						border: 1,
						borderColor: "#262835",
						borderTop: 0,
						boxShadow: "0px 0px 20px #262835",
					}}
				>
					<Grid
						container
						item
						width="100%"
						pl={2}
						display="flex"
						minHeight="50px"
						maxHeight="50px"
						alignItems="center"
						sx={{
							background: "#04598c",
							boxShadow: "10px 3px 10px -5px #262835",
							borderTopLeftRadius: "20px",
							borderTopRightRadius: "20px",
						}}
					>
						<Grid
							item
							xs={8}
							display="flex"
							flexDirection="row"
							alignItems="center"
						>
							{languageLogo && (
								<img
									src={languageLogo}
									alt=""
									style={{
										maxHeight: "33px",
									}}
								/>
							)}
							{/* Edit button */}
							<Avatar
								sx={{
									bgcolor: "#262835",
									// mr: 1,
									ml: 2,
									width: 38,
									height: 38,
								}}
								className={classes.buttonCursor}
							>
								<IconButton
									color="primary"
									onClick={() => setEditModelPopupOpen(true)}
								>
									<EditIcon />
								</IconButton>
							</Avatar>
							<Typography variant="h6" color="#ffffff" ml={2}>
								{model?.title}
							</Typography>
						</Grid>
						<Grid
							item
							xs={4}
							display="flex"
							flexDirection="row"
							alignItems="center"
							justifyContent="flex-end"
							pr={1}
						>
							{supportsLLM && (
								<Tooltip
									title="Locsys assistant"
									sx={{
										cursor: "pointer",
									}}
									onClick={() => {
										setPromptPopupOpen(true);
									}}
								>
									<img
										src={LlmIcon}
										alt=""
										style={{
											maxHeight: "30px",
											borderRadius: "50%",
											backgroundColor: "#ddd",
											padding: "3px",
											marginRight: "5px",
											border: "1px solid #fff",
											boxShadow: "10px 3px 10px -5px #262835",
											cursor: "pointer",
										}}
									/>
								</Tooltip>

							)}
							{supportsGeneration && model?.finalValidations[0]?.validated === true && !hasChanged && (
								<>
									<Chip
										label="Generate code"
										color="warning"
										size="small"
										className={[classes.clickableChip].join(" ")}
										sx={{
											mr: 1,
										}}
										onClick={() => handleGenerateCode(model?.finalValidations[0]?._id)}
									/>
									<FormControl variant="standard" sx={{ minWidth: 120 }}>
										<Select
											className={classes.selectSmall}
											value={selectedGeneration ?? ""}
											onChange={(event) => {
												setSelectedGeneration(event.target.value);
											}}
										>
											{
												generationTypes.map((e, ind) => (
													<MenuItem key={ind} value={e}>{e}</MenuItem>
												))
											}
										</Select>
									</FormControl>
									<MoreVertIcon mx={1} />
								</>
							)}
							{supportsDeployment && model?.finalValidations[0]?.validated === true && !hasChanged && (
								<>
									<Chip
										size="small"
										label="Deploy code"
										color="warning"
										className={[classes.clickableChip].join(" ")}
										sx={{
											mr: 0.5,
										}}
										onClick={() => handleDeployCode(model?.finalValidations[0]?._id)}
									/>
									<MoreVertIcon mx={1} />
								</>
							)}
							{
								dslConstants.hasValidation && (
									<Chip
										size="small"
										label="Validate model"
										color="success"
										className={[classes.clickableChip].join(" ")}
										sx={{
											mr: 0.5,
										}}
										onClick={validateModelInternal}
									/>
								)
							}
							{autosave === true && (
								<Chip
									size="small"
									label={saved ? "Saved" : "Saving..."}
									color={saved ? "primary" : "warning"}
									sx={{
										mr: 0.5,
									}}
								/>
							)}
							{autosave === false && (
								<Chip
									size="small"
									label={saved ? "Saved" : "Save"}
									color={saved ? "primary" : "error"}
									className={[classes.clickableChip].join(" ")}
									sx={{
										mr: 0.5,
									}}
									onClick={saveModelText}
								/>
							)}
							<MoreVertIcon mx={1} />
							<Chip
								label="Delete model"
								color="error"
								size="small"
								className={[classes.clickableChip].join(" ")}
								sx={{
									mr: 0.5,
								}}
								onClick={() => { setDeleteModelPopupOpen(true); }}
							/>
							{actions.length > 0 && (
								<SpeedDial
									key={-4}
									ariaLabel="SpeedDial tooltip example"
									sx={{ position: 'absolute', bottom: 40 }}
									icon={<SpeedDialIcon />}
									open={open}
									onClose={handleClose}
									onOpen={handleOpen}
								>
									{actions.map((action) => (
										<SpeedDialAction
											key={action.name}
											tooltipOpen
											icon={action.icon}
											tooltipTitle={action.name}
											onClick={() => {
												handleClose();
												const txt = insertables[action.name];
												handleAddText(txt);
											}}
										/>
									))}
								</SpeedDial>
							)}

							{
								comparisonMode && (
									<Chip
										key={-4}
										label="Close comparison"
										color="warning"
										className={[classes.clickableChip].join(" ")}
										sx={{
											mr: 0.5,
										}}
										onClick={() => {
											setComparisonMode(false);
											setSelectedValidation(null);
											setOldValue("");
										}}
									/>
								)
							}
						</Grid>
					</Grid>
					<Grid
						item
						display="flex"
						flexDirection="column"
						alignItems="center"
					>
						<Grid
							item
							sx={{
								// borderRadius: 10,
								alignItems: "center",
								flex: 1,
								width: "100%",
							}}
							maxHeight="calc(100vh - 265px)"
							minHeight="calc(100vh - 265px)"
							height="calc(100vh - 265px)"
							overflow='auto'
						>
							{dslConstants?.type === "Textual" && (
								<>
									{!comparisonMode && (
										<CodeMirror
											ref={codeMirrorRef}
											value={value}
											maxHeight="calc(100vh - 265px)"
											minHeight="calc(100vh - 265px)"
											height="calc(100vh - 265px)"
											extensions={[
												javascript({ jsx: true }),
												EditorView.lineWrapping,
												customLinter,
												lintGutter(),
												customHighlighter,
											]}
											onChange={onChange}
										/>
									)}
									{comparisonMode && (
										<CodeMirrorMerge
											key={Math.random()}
											style={{
												maxHeight: "calc(100vh - 265px)",
												minHeight: "calc(100vh - 265px)",
												height: "calc(100vh - 265px)",
											}}
											origin={oldValue}
										>
											<Original
												value={value}
												style={{
													maxHeight: "calc(100vh - 265px)",
													minHeight: "calc(100vh - 265px)",
													height: "calc(100vh - 265px)",
												}}
												extensions={[javascript({ jsx: true }), EditorView.lineWrapping]}
											/>
											<Modified
												value={oldValue}
												style={{
													maxHeight: "calc(100vh - 265px)",
													minHeight: "calc(100vh - 265px)",
													height: "calc(100vh - 265px)",
												}}
												extensions={[EditorView.editable.of(false), EditorState.readOnly.of(true), EditorView.lineWrapping]}
											/>
										</CodeMirrorMerge>
									)}
								</>
							)}
							{dslConstants?.type === "Graphical" && (
								<>
									{dslConstants.short === "envmaker" && (<CreateMap modelUpdate={setValue} model={value} />)}
									{dslConstants.short === "envpop" && (<CreateMission modelUpdate={setValue} model={value} dbitem={model} />)}
									{dslConstants.short === "envpopmaker" && (<CreateEnvironment modelUpdate={setValue} model={value} dbitem={model} />)}
									{dslConstants.short === "appcreator" && (<Testbed modelUpdate={setValue} model={value} modelid={modelid} />)}
								</>
							)}

						</Grid>
					</Grid>
				</Grid>
				<Grid
					container
					item
					width="100%"
					xs={2.9}
					display="flex"
					flexDirection="column"
					sx={{
						border: 1,
						borderColor: "#262835",
						borderTop: 0,
						borderRadius: "20px",
						boxShadow: "0px 0px 20px #262835",
					}}
				>
					<Grid
						container
						item
						width="100%"
						display="flex"
						minHeight="50px"
						maxHeight="50px"
						sx={{
							background: "#04598c",
							boxShadow: "10px 3px 10px -5px #262835",
							borderTopLeftRadius: "20px",
							borderTopRightRadius: "20px",
						}}
					>
						<Box
							sx={{
								width: "100%",
								bgcolor: '#04598c',
								borderRadius: "20px",
								borderTopLeftRadius: "20px",
							}}
						>
							<Tabs
								selectionFollowsFocus
								scrollButtons="auto"
								variant="scrollable"
								value={tabValue}
								onChange={handleTabChange}
							>
								<StyledTab label="Development" />
								<StyledTab label="Documentation" />
								{(supportsGeneration || supportsDeployment) && <StyledTab label="Output" />}
							</Tabs>
						</Box>

					</Grid>
					{ /* Development tab */}
					{tabValue === 0 && (
						<Grid
							item
							sx={{
								alignItems: "center",
								flex: 1,
								width: "100%",
							}}
							maxHeight="calc(100vh - 265px)"
							overflow='auto'
						>
							{/* Validation results and tools here */}
							{dslConstants.short === "appcreator" && (
								<>
									<Card
										sx={{
											width: "100%",
											backgroundColor: "#fff",
											pb: 1,
											pl: 1,
											mt: 1,
										}}
									>
										<Typography
											variant="h6"
											sx={{
												p: 1,
												color: "text.secondary",
											}}
										>
											{"Available variables"}
										</Typography>
										{storeVariables.length === 0 && (
											<Typography
												variant="body2"
												sx={{
													px: 1,
													pl: 2,
													fontSize: "0.9em",
													color: "text.secondary",
												}}
											>
												{"No variables found"}
											</Typography>
										)}
										{storeVariables.length > 0 && (
											<ReactJson
												// theme="railscasts"
												collapsed={1}
												displayDataTypes={false}
												quotesOnKeys={false}
												src={parsedVariables}
												style={{ body: { fontSize: '10px', }, }}
											/>
										)}
									</Card>
									<Card
										sx={{
											width: "100%",
											backgroundColor: "#fff",
											pb: 1,
											pl: 1,
											mt: 1,
										}}
									>
										<Grid
											item
											container
											flowdirection="row"
											alignContent="baseline"
											p={1}
										>
											<Typography
												variant="h6"
												sx={{
													// p: 1,
													color: "text.secondary",
												}}
											>
												{"Available brokers"}
											</Typography>
											{/* Icon to add */}
											<Chip
												key={-1}
												label="+"
												color="primary"
												sx={{
													mr: 0.5,
													ml: 1,
													"& .MuiChip-label": {
														color: "#ffffff",
													},
												}}
												onClick={() => {
													setBrokerToEdit({ id: Math.random().toString() });
													setAddBrokerPopupOpen(true);
												}}
											/>
										</Grid>
										{storeBrokers.length === 0 && (
											<Typography
												variant="body2"
												sx={{
													px: 1,
													pl: 2,
													fontSize: "0.9em",
													color: "text.secondary",
												}}
											>
												{"No brokers found"}
											</Typography>
										)}
										{storeBrokers.map((broker) => (
											<Grid
												key={broker.id}
												item
												container
												flowdirection="row"
												pl={2}
											>
												{/* Add icon for delete */}
												<Tooltip title="Delete broker">
													<DeleteIcon
														color="secondary"
														className={[classes.clickableChip].join(" ")}
														onClick={() => {
															dispatch(deleteBroker(broker.id));
														}}
													/>
												</Tooltip>
												{/* Add icon for edit */}
												<Tooltip title="Edit broker">
													<ModeEditIcon
														color="secondary"
														className={[classes.clickableChip].join(" ")}
														onClick={() => {
															setBrokerToEdit(broker.parameters);
															setAddBrokerPopupOpen(true);
														}}
													/>
												</Tooltip>
												<Typography
													sx={{
														color: "text.secondary",
														fontSize: "0.9em",
														px: 1,
													}}
												>
													{broker.parameters.name}
													{" - ["}
													{broker.parameters.host}
													{":"}
													{broker.parameters.port}
													{"]"}
												</Typography>

											</Grid>
										))}
									</Card>
									<Card
										sx={{
											width: "100%",
											backgroundColor: "#fff",
											pb: 1,
											pl: 1,
											mt: 1,
										}}
									>
										<Typography
											variant="h6"
											sx={{
												p: 1,
												color: "text.secondary",
											}}
										>
											{"Logs"}
										</Typography>
										{storeLogs.length === 0 && (
											<Typography
												variant="body2"
												sx={{
													px: 1,
													pl: 2,
													fontSize: "0.9em",
													color: "text.secondary",
												}}
											>
												{"No logs exist"}
											</Typography>
										)}
										{storeLogs.map((log) => (
											<Typography
												key={Math.random()}
												sx={{
													color: "text.secondary",
													fontSize: "0.9em",
													px: 1,
													pl: 2,
												}}
											>
												{"["}
												{log.payload.timestamp}
												{"] "}
												{log.payload.message}
												{" (Node "}
												{log.payload.node_count}
												{")"}
											</Typography>
										))}
									</Card>
									<Card
										sx={{
											width: "100%",
											backgroundColor: "#fff",
											pb: 1,
											pl: 1,
											mt: 1,
										}}
									>
										<Typography
											variant="h6"
											sx={{
												p: 1,
												color: "text.secondary",
											}}
										>
											{"Errors"}
										</Typography>
										{("payload" in validationErrors === false || validationErrors?.payload?.length === 0) && (
											<Typography
												variant="body2"
												sx={{
													px: 1,
													pl: 2,
													fontSize: "0.9em",
													color: "text.secondary",
												}}
											>
												{"No validation errors exist"}
											</Typography>
										)}
										{validationErrors?.payload?.map((_error) => (
											<Typography
												key={Math.random()}
												sx={{
													color: "text.secondary",
													fontSize: "0.9em",
													px: 1,
													pl: 2,
												}}
											>
												{"- [Node "}
												{_error.id}
												{"] "}
												{_error.message}
											</Typography>
										))}
									</Card>
								</>
							)}
							<Grid
								item
								display="flex"
								width="100%"
								flexDirection="column"
								alignItems="center"
							>
								{/* Check if this model is imported anywhere else */}
								{model?.countImports > 0 && (
									<Card
										sx={{
											width: "100%",
											mx: 2,
											mt: 2,
											backgroundColor: "#ffcccc",
										}}
									>
										<CardContent>
											<Typography
												variant="body2"
												sx={{
													pt: 1,
												}}
											>
												{`This model is imported in ${model?.countImports} other model${model?.countImports === 1 ? "" : "s"}. If you change it you may break something! Models that import this one:`}
											</Typography>
											<List dense>
												{model?.importedBy.map((m, ind) => (
													<ListItem
														key={ind}
														className={classes.denseListItem}
													>
														<ListItemIcon
															sx={{
																ml: 1,
															}}
														>
															<Typography
																sx={{
																	fontSize: '0.9rem',
																	pt: 0.2,
																	pr: 1,
																	fontWeight: 'bold',
																}}
															>
																{dslsNames[m.type]}
															</Typography>
														</ListItemIcon>
														<ListItemText>
															<Grid container display="flex" flexDirection="row">
																<Typography
																	sx={{
																		fontSize: '0.9rem',
																		pt: 0.2,
																		pr: 2,
																		fontWeight: 'bold',
																	}}
																>
																	{m.title}
																</Typography>
																<Tooltip title="Go to model">
																	<VisibilityIcon
																		color="primary"
																		className={[classes.clickableChip].join(" ")}
																		onClick={() => {
																			window.open(`/dsls/${m.type}/${m.id}`);
																		}}
																	/>
																</Tooltip>
															</Grid>
														</ListItemText>
													</ListItem>
												))}
											</List>
										</CardContent>
									</Card>
								)}
								{subtypes.length > 1 && (
									<Card
										sx={{
											width: "100%",
											mx: 2,
											mt: 2,
										}}
									>
										<CardContent>
											<Grid
												item
												display="flex"
												width="100%"
												flexDirection="row"
												pt={1}
											>
												<Typography
													gutterBottom
													variant="h6"
													sx={{ mr: 2 }}
													color="text.secondary"
												>
													{"Model type"}
												</Typography>
												<FormControl variant="standard" sx={{ minWidth: 120 }}>
													<Select
														className={classes.select}
														value={selectedSubtype ?? ""}
														onChange={(event) => {
															setSelectedSubtype(event.target.value);
															saveSubtype(event.target.value);
														}}
													>
														{
															subtypes.map((e, ind) => (
																<MenuItem key={ind} value={e}>{e}</MenuItem>
															))
														}
													</Select>
												</FormControl>
											</Grid>
										</CardContent>
									</Card>
								)}
								{/* Chip with creator fullname */}
								{/* Show if the creator is different than me */}
								{
									model?.creator?._id !== user.id && (
										<Chip
											label={`Owner: ${model?.creator?.fullname}`}
											color="warning"
											sx={{
												mr: 1,
												ml: 1,
												mt: 1,
												mb: 1,
											}}
										/>
									)
								}
								{supportsValidation === true && dslConstants.short !== "appcreator" && (
									<Card
										sx={{
											width: "100%",
											mx: 2,
											mt: 0,
										}}
									>
										<CardContent>

											<Typography
												gutterBottom
												variant="h6"
												// sx={{ fontSize: 14 }}
												color="text.secondary"
											>
												{"Last validation result"}
											</Typography>
											<Typography
												variant="h6"
												component="div"
												sx={{
													p: 2,
													my: 1,
													borderRadius: 4,
													backgroundColor: model?.finalValidations[0]?.validated ? "#ccffcc" : "#ffcccc",
												}}
											>
												{model?.finalValidations[0]?.validated ? "Validated" : "Not validated"}
											</Typography>
											<Typography
												variant="body2"
												sx={{
													pt: 1,
												}}
											>
												{`Checked on ${model?.finalValidations[0]?.updatedAt.replace("T", " ").replace("Z", "")}`}
											</Typography>
											{model?.finalValidations[0]?.validated === false && (
												<Typography
													variant="body2"
													sx={{
														p: 2,
														my: 1,
														borderRadius: 4,
														backgroundColor: "#ffcccc",
													}}
												>
													{`Error: ${model?.finalValidations[0]?.validation_response}`}
												</Typography>
											)}

											<Typography
												variant="body2"
												sx={{
													pt: 1,
													textDecoration: "underline",
													cursor: "pointer",
												}}
												onClick={() => {
													setValidationsPopupOpen(true);
												}}
											>
												{`See all validations (${model?.finalValidations.length})`}
											</Typography>
										</CardContent>
									</Card>
								)}
								{/* If it does not support validation, inform the user */}
								{supportsValidation === false && dslConstants.short !== "appcreator" && (
									<Card
										sx={{
											width: "100%",
											mx: 2,
											mt: 2,
										}}
									>
										<CardContent>
											<Typography
												gutterBottom
												variant="h6"
												// sx={{ fontSize: 14 }}
												color="text.secondary"
											>
												{"Validation not supported"}
											</Typography>
											<Typography
												variant="body2"
												sx={{
													pt: 1,
												}}
											>
												{`This DSL does not support validation`}
											</Typography>
										</CardContent>
									</Card>
								)}
							</Grid>
							{/* Show information in case this model was created by a transformation */}
							{model?.originModel && (
								<Card
									sx={{
										width: "100%",
										mt: 2,
										backgroundColor: "#ddffdd",
									}}
								>
									<CardContent>
										<Typography
											variant="body2"
											sx={{
												pt: 1,
												mb: 1,
											}}
										>
											{`This model was created by transforming the following model:`}
										</Typography>
										<Grid container display="flex" flexDirection="row">
											<Typography
												sx={{
													fontSize: '0.9rem',
													pt: 0.2,
													pr: 1,
												}}
											>
												{dslsNames[model.originModel.model_type]}
											</Typography>
											<Typography
												sx={{
													fontSize: '0.9rem',
													pt: 0.2,
													pr: 2,
													fontWeight: 'bold',
												}}
											>
												{model.originModel.title}
											</Typography>
											<Tooltip title="Go to model">
												<VisibilityIcon
													color="primary"
													className={[classes.clickableChip].join(" ")}
													onClick={() => {
														window.open(`/dsls/${model.originModel.model_type}/${model.originModel._id}`);
													}}
												/>
											</Tooltip>
										</Grid>
									</CardContent>
								</Card>
							)}
							{hasRelationToOtherDsls && (
								<Grid
									item
									sx={{
										alignItems: "center",
										flex: 1,
										width: "100%",
									}}
									maxHeight="calc(100vh - 265px)"
									overflow='auto'
								>
									<Grid
										item
										display="flex"
										width="100%"
										flexDirection="column"
										alignItems="center"
									>
										<Card
											sx={{
												width: "100%",
												mx: 2,
												mt: 2,
											}}
										>
											<CardContent>
												{canTransformTo.length > 0 && (
													<>
														<Typography
															variant="body2"
															sx={{
																pt: 1,
															}}
														>
															{`You can transform this model to the following types:`}
														</Typography>
														<List
															item
															dense
														>
															{canTransformTo.map((v, indint) => (
																<ListItem
																	key={indint}
																	className={classes.denseListItem}
																>
																	<ListItemIcon
																		sx={{
																			ml: 1,
																		}}
																	>
																		<img
																			src={dslsImages[v]}
																			alt=""
																			style={{
																				maxHeight: "30px",
																			}}
																		/>
																	</ListItemIcon>
																	<Chip
																		key={indint}
																		label="Transform"
																		color="secondary"
																		className={[classes.clickableChip].join(" ")}
																		sx={{
																			ml: 1,
																		}}
																		onClick={() => {
																			setDslToTransform(v);
																			setAddModelPopupOpen(true);
																		}}
																	/>
																</ListItem>
															))}
														</List>
													</>
												)}
												{canImport.length > 0 && (
													<>
														<Typography
															variant="body2"
															sx={{
																pt: 1,
															}}
														>
															{`You can import models from the following DSLs into this model:`}
														</Typography>
														<List
															item
															dense
														>
															{canImport.map((v, indint) => (
																<ListItem
																	key={indint}
																	className={classes.denseListItem}
																>
																	<ListItemIcon
																		sx={{
																			ml: 1,
																		}}
																	>
																		<img
																			src={dslsImages[v]}
																			alt=""
																			style={{
																				maxHeight: "30px",
																			}}
																		/>
																	</ListItemIcon>
																	<Chip
																		key={indint}
																		label="Import model"
																		color="secondary"
																		className={[classes.clickableChip].join(" ")}
																		sx={{
																			ml: 1,
																		}}
																		onClick={() => {
																			handleGetUserModels(v);
																		}}
																	/>
																</ListItem>
															))}
														</List>
													</>
												)}
												{mustImport.length > 0 && (
													<>
														<Typography
															variant="body2"
															sx={{
																pt: 1,
															}}
														>
															{`You MUST import models from the following DSLs into this model:`}
														</Typography>
														<List
															item
															dense
														>
															{mustImport.map((v, indint) => (
																<ListItem
																	key={indint}
																	className={classes.denseListItem}
																>
																	<ListItemIcon
																		sx={{
																			ml: 1,
																		}}
																	>
																		<img
																			src={dslsImages[v]}
																			alt=""
																			style={{
																				maxHeight: "30px",
																			}}
																		/>
																	</ListItemIcon>
																	<Chip
																		key={indint}
																		label="Import"
																		color="secondary"
																		className={[classes.clickableChip].join(" ")}
																		sx={{
																			ml: 1,
																		}}
																		onClick={() => {
																			handleGetUserModels(v);
																		}}
																	/>
																</ListItem>
															))}
														</List>
													</>
												)}
											</CardContent>
										</Card>
										{/* Add information about imported models */}
										{model?.finalImports?.length > 0 && (
											<Card
												sx={{
													width: "100%",
													mx: 2,
													mt: 2,
												}}
											>
												<Typography variant="h6" sx={{ ml: 2, mt: 2 }}>
													{"Imported models"}
												</Typography>
												<CardContent>
													<List
														item
														dense
													>
														{model?.finalImports.map((v, indint) => (
															<ListItem
																key={indint}
																className={classes.denseListItem}
															>
																<ListItemIcon
																	sx={{
																		ml: 1,
																		mr: 2,
																	}}
																>
																	<img
																		src={dslsImages[v.model_type]}
																		alt=""
																		style={{
																			maxHeight: "30px",
																		}}
																	/>
																</ListItemIcon>
																{/* Name of the model */}
																<Chip
																	key={`${indint}_name`}
																	label={v.title}
																	color="primary"
																	sx={{
																		ml: 1,
																	}}
																/>
																{/* Link to imported model */}
																<Chip
																	key={indint}
																	label="View"
																	color="secondary"
																	className={[classes.clickableChip].join(" ")}
																	sx={{
																		ml: 1,
																	}}
																	onClick={() => {
																		window.open(`/dsls/${v.model_type}/${v._id}`);
																	}}
																/>
															</ListItem>
														))}
													</List>
												</CardContent>
											</Card>
										)}
									</Grid>
								</Grid>
							)}
						</Grid>
					)}
					{ /* Documentation tab */}
					{tabValue === 1 && (
						<Grid
							item
							sx={{
								alignItems: "center",
								flex: 1,
								width: "100%",
							}}
						>
							<MDEditor
								hideToolbar
								value={documentation}
								height={screenSize.height - 263}
								maxHeight={1200}
								fullscreen={false}
								tabSize={2}
								preview="preview"
								style={{
									zIndex: 100,
								}}
							/>
						</Grid>
					)}
					{ /* Generated code tab */}
					{tabValue === 2 && (supportsGeneration || supportsDeployment) && (
						<Grid
							item
							sx={{
								alignItems: "center",
								flex: 1,
								width: "100%",
							}}
						>
							{model?.generated_code && supportsGeneration && (
								<>
									{deploymentInformation && (
										<MDEditor
											hideToolbar
											value={deploymentInformation ?? "No generation information yet"}
											height={(screenSize.height - 263) * 0.33}
											maxHeight={1200}
											fullscreen={false}
											tabSize={2}
											preview="preview"
											style={{
												zIndex: 100,
											}}
										/>
									)}
									<Chip
										label="Copy generated code"
										color="secondary"
										size="small"
										className={[classes.clickableChip].join(" ")}
										sx={{
											my: 0.5,
										}}
										onClick={async () => {
											await navigator.clipboard.writeText(generatedCode);
											success("Code copied to clipboard");
										}}
									/>
									<CodeMirror
										ref={codeMirrorGenerationRef}
										value={generatedCode ?? "# No generated code yet"}
										height={`${(screenSize.height - 297) * 0.66}px`}
										maxheight={`${(screenSize.height - 297) * 0.66}px`}
										minHeight={`${(screenSize.height - 297) * 0.66}px`}
										extensions={[codemirrorGeneratedLanguage, EditorView.lineWrapping]}
									/>
								</>
							)}
							{deploymentInformation && supportsDeployment && (
								<MDEditor
									hideToolbar
									value={deploymentInformation ?? "No deployment information yet"}
									height={screenSize.height - 263}
									maxHeight={1200}
									fullscreen={false}
									tabSize={2}
									preview="preview"
									style={{
										zIndex: 100,
									}}
								/>
							)}
						</Grid>
					)}
				</Grid>
			</Grid>
		</>
	);
};

export default memo(DslModelScreen);
