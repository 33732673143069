import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useMemo, useState } from "react";

import { ProgressBar, PreviousButton, NextButton, CreateMap, PlaceObjects } from "./components/index.js";
import Spinner from "../Spinner.js";

const useStyles = makeStyles(() => ({
	root: {
		width: "100%",
		minHeight: "100%",
		display: "flex",
		flexDirection: "column",
		position: "relative",
	},
	mainBox: {
		width: "100%",
		height: "calc(100% - 100px)",
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		position: "absolute",
		top: "50px",
	},
	buttonsBox: {
		width: "100%",
		height: "50px",
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "space-evenly",
		position: "absolute",
	},
}));

const INITIAL_GRID_DIMENSION = 120;
const INITIAL_GRID_METERS = 20;

const CreateEnvironment = () => {
	const classes = useStyles();

	const [currentStep, setCurrentStep] = useState(0);
	// eslint-disable-next-line no-unused-vars
	const [spinnerOpen, setSpinnerOpen] = useState(false);

	// Map general related variables
	const [resolution, setResolution] = useState(INITIAL_GRID_METERS / INITIAL_GRID_DIMENSION);
	const [gridMeters, setGridMeters] = useState(INITIAL_GRID_METERS);
	const [gridDimension, setGridDimension] = useState(INITIAL_GRID_DIMENSION);
	const [mapWidth, setMapWidth] = useState(100);
	const [mapHeight, setMapHeight] = useState(80);
	const [mapWidthMeters, setMapWidthMeters] = useState(100 * (INITIAL_GRID_METERS / INITIAL_GRID_DIMENSION));

	// Create map variables
	const [mode, setMode] = useState(null);
	const [boxes, setBoxes] = useState({});
	const [numberOfRows, setNumberOfRows] = useState(4);
	const [numberOfColumns, setNumberOfColumns] = useState(6);
	const [mapImage, setMapImage] = useState(null);

	const steps = useMemo(() => ([
		{
			id: "create-map",
			title: "Create Map",
		},
		{
			id: "place-objects",
			title: "Add Sensors & Obstacles",
		},
		{
			id: "places",
			title: "Add Places",
		},
	]), []);

	console.log(`Resolution: ${resolution}`);
	console.log(`Grid Meters: ${gridMeters}`);
	console.log(`Grid Dimension: ${gridDimension}`);
	console.log(`Map Width: ${mapWidth}`);
	console.log(`Map Height: ${mapHeight}`);
	console.log(`Map Width Meters: ${mapWidthMeters}`);

	const setMapDimensions = (width, height, isFirstTime = false) => {
		setMapWidth(width);
		setMapHeight(height);
		if (isFirstTime) {
			setMapWidthMeters(width * resolution);
		}
	};

	return (
		<Grid container className={classes.root} overflowY="auto">
			<ProgressBar progress={currentStep} steps={steps} />
			<Grid item className={classes.mainBox}>
				{steps[currentStep].id === "create-map" && (
					<CreateMap
						key="create-map"
						resolution={resolution}
						gridMeters={gridMeters}
						gridDimension={gridDimension}
						mapWidth={mapWidth}
						mapHeight={mapHeight}
						mapWidthMeters={mapWidthMeters}
						mode={mode}
						boxes={boxes}
						numberOfRows={numberOfRows}
						numberOfColumns={numberOfColumns}
						mapImage={mapImage}
						setResolution={setResolution}
						setGridMeters={setGridMeters}
						setGridDimension={setGridDimension}
						setMapDimensions={setMapDimensions}
						setMode={setMode}
						setBoxes={setBoxes}
						setNumberOfRows={setNumberOfRows}
						setNumberOfColumns={setNumberOfColumns}
						setMapImage={setMapImage}
					/>
				)}
				{steps[currentStep].id === "place-objects" && (
					<PlaceObjects
						key="place-objects"
						gridMeters={gridMeters}
						gridDimension={gridDimension}
						mapWidth={mapWidth}
						mapHeight={mapHeight}
						mapWidthMeters={mapWidthMeters}
						mode={mode}
						boxes={boxes}
						numberOfRows={numberOfRows}
						numberOfColumns={numberOfColumns}
						mapImage={mapImage}
						setGridDimension={setGridDimension}
						setMapDimensions={setMapDimensions}
					/>

				)}
				{steps[currentStep].id === "places" && (
					<div style={{ color: "white" }}>{"Places"}</div>
				)}
			</Grid>
			<Grid item className={classes.buttonsBox} sx={{ bottom: { md: "0px", xs: "-120px" } }}>
				<PreviousButton
					title="Previous"
					disabled={currentStep === 0}
					onClick={() => setCurrentStep(currentStep - 1)}
				/>
				<NextButton
					title="Next"
					disabled={currentStep === steps.length - 1}
					onClick={() => setCurrentStep(currentStep + 1)}
				/>
			</Grid>
			<Spinner key="spinner" open={spinnerOpen} />
		</Grid>
	);
};

export default CreateEnvironment;
