import { Grid, Step, StepConnector, StepLabel, Stepper, stepConnectorClasses, styled } from "@mui/material";
import { useEffect, useState } from "react";

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
	[`& .${stepConnectorClasses.line}`]: {
		height: 2,
		border: 0,
		backgroundColor: theme.palette.green.main,
		borderRadius: 1,
		margin: "10px 20px",
	},
}));

const ColorStepLabel = styled(StepLabel)(({ theme, active }) => ({
	"& .MuiStepLabel-label": {
		color: `${active ? theme.palette.green.main : theme.palette.grey.dark} !important`,
		fontWeight: `${active ? "bold" : "normal"} !important`,
	},
	"& .MuiStepIcon-root": {
		color: `${active ? theme.palette.green.main : theme.palette.grey.dark} !important`,
		fontSize: "2rem",
	},
}));

const ProgressBar = ({ progress, steps }) => {
	const [step, setStep] = useState(0);
	const [allSteps, setAllSteps] = useState(steps);

	useEffect(() => {
		setStep(progress);
	}, [progress]);

	useEffect(() => {
		setAllSteps(steps);
	}, [steps]);

	return (
		<Grid item width="100%" height="50px" p={1}>
			<Stepper activeStep={step} connector={<ColorlibConnector />}>
				{allSteps.map(({ id, title }, ind) => (
					<Step key={id}>
						<ColorStepLabel active={step === ind}>
							{title}
						</ColorStepLabel>
					</Step>
				))}
			</Stepper>
		</Grid>
	);
};

export default ProgressBar;
