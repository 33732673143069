import { getSmautoConstants } from "./smauto.js";
import { getDemolConstants } from "./demol.js";
import { getCpsmlConstants } from "./cpsml.js";
import { getGoaldslConstants } from "./goaldsl.js";
import { getGenerosConstants } from "./generos.js";
import { getRosbridgemlConstants } from "./rosbridgeml.js";
import { getEnvmakerConstants } from "./envmaker.js";
import { getEnvpopConstants } from "./envpop.js";
import { getEnvpopmakerConstants } from "./envpopmaker.js";
import { getDflowConstants } from "./dflow.js";
import { getCodinConstants } from "./codin.js";
import { getXmasdslConstants } from "./xmasdsl.js";
import { getCommidlConstants } from "./commidl.js";
import { getAppCreatorConstants } from "./appcreator.js";
import { getToolBoxerConstants } from "./toolboxer.js";

/**
 * Mapping of DSL names to their corresponding constant functions.
 * @type {Object.<string, Function>}
 */
const dslsMappings = {
	appcreator: getAppCreatorConstants,
	envmaker: getEnvmakerConstants,
	envpop: getEnvpopConstants,
	envpopmaker: getEnvpopmakerConstants,
	smauto: getSmautoConstants,
	codintxt: getCodinConstants,
	toolboxer: getToolBoxerConstants,
	demol: getDemolConstants,
	cpsml: getCpsmlConstants,
	goaldsl: getGoaldslConstants,
	generos: getGenerosConstants,
	rosbridgeml: getRosbridgemlConstants,
	dflow: getDflowConstants,
	xmasdsl: getXmasdslConstants,
	commidl: getCommidlConstants,
};

/**
 * Retrieves the constants for a specific DSL.
 * @param {string} dsl - The DSL name.
 * @returns {Promise<any>} - The constants for the specified DSL.
 */
export const getDslConstants = (dsl) => {
	//  if dsl not in mappings return empty object
	if (!(dsl in dslsMappings)) return {};
	return dslsMappings[dsl]();
};

/**
 * Retrieves the constants for all DSLs.
 * @returns {Promise<Array<Object>>} - A promise that resolves to an array of DSL constants.
 */
export const getDslsConstants = async () => {
	const dsls = [];
	// eslint-disable-next-line guard-for-in
	for (const dsl in dslsMappings) dsls.push(await dslsMappings[dsl]());
	return dsls;
};

/**
 * Retrieves the DSLs images.
 * @param {boolean} [light=false] - Indicates whether to retrieve the light version of the images.
 * @returns {Promise<Object>} - A promise that resolves to an object containing the DSLs logos.
 */
export const getDslsImages = async (light = false) => {
	const dsls = await getDslsConstants();
	const logos = {};
	for (const dsl of dsls) logos[dsl.short] = light ? dsl.logoUrlDark : dsl.logoUrl;
	return logos;
};

/**
 * Retrieves the names of DSLs.
 * @returns {Promise<Object>} An object containing the DSL names.
 */
export const getDslsNames = async () => {
	const dsls = await getDslsConstants();
	const names = {};
	for (const dsl of dsls) names[dsl.short] = dsl.name;
	return names;
};

/**
 * Retrieves the DSL keywords from the given DSL.
 * @param {string} dsl - The DSL to extract keywords from.
 * @returns {Promise<Array<string>>} - A promise that resolves to an array of DSL keywords.
 */
export const getDslKeywords = async (dsl) => {
	const constants = await getDslConstants(dsl);
	return constants.keywords;
};
