import city from "./city.js";
import farm from "./farm.js";
import blueprint from "./blueprint.js";
import home from "./home.js";
import supermarket from "./supermarket.js";

const categories = {
	home,
	blueprint,
	city,
	farm,
	supermarket,
};

export default categories;
