import { Button } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
	previousButton: {
		padding: "5px 10px",
		width: "100px",
		height: "40px",
		backgroundColor: theme.palette.secondary.main,
		color: theme.palette.white.main,
		transition: "background-color 0.4s ease",
		"&:before": {
			content: '""',
			color: "transparent",
			position: "absolute",
			left: "-18px",
			borderStyle: "solid",
			borderWidth: "20px 20px 20px 0",
			borderColor: `transparent ${theme.palette.secondary.main} transparent transparent`,
			transition: "border-color 0.4s ease",
		},
		"&:hover": {
			backgroundColor: theme.palette.secondary.dark,
			"&:before": {
				backgroundColor: "transparent",
				borderColor: `transparent ${theme.palette.secondary.dark} transparent transparent`,
			},
		},
		"&:disabled": {
			backgroundColor: theme.palette.grey.main,
			color: theme.palette.grey.dark,
			cursor: "not-allowed",
			"&:before": {
				borderColor: `transparent ${theme.palette.grey.main} transparent transparent`,
			},
		},
	},
	nextButton: {
		padding: "5px 10px",
		width: "100px",
		height: "40px",
		backgroundColor: theme.palette.secondary.main,
		color: theme.palette.white.main,
		cursor: "pointer",
		transition: "background-color 0.4s ease",
		"&:after": {
			content: '""',
			color: "transparent",
			position: "absolute",
			right: "-18px",
			borderStyle: "solid",
			borderWidth: "20px 0px 20px 20px",
			borderColor: `transparent transparent transparent ${theme.palette.secondary.main}`,
			transition: "border-color 0.4s ease",
		},
		"&:hover": {
			backgroundColor: theme.palette.secondary.dark,
			"&:after": {
				backgroundColor: "transparent",
				borderColor: `transparent transparent transparent ${theme.palette.secondary.dark}`,
			},
		},
		"&:disabled": {
			backgroundColor: theme.palette.grey.main,
			color: theme.palette.grey.dark,
			cursor: "not-allowed",
			"&:after": {
				borderColor: `transparent transparent transparent ${theme.palette.grey.main}`,
			},
		},
	},
}));

export const PreviousButton = ({ title, disabled, onClick }) => {
	const classes = useStyles();

	return (
		<Button
			type="button"
			disabled={disabled}
			className={classes.previousButton}
			onClick={onClick}
		>
			{title || "Previous"}
		</Button>
	);
};

export const NextButton = ({ title, disabled, onClick }) => {
	const classes = useStyles();

	return (
		<Button
			type="button"
			disabled={disabled}
			className={classes.nextButton}
			onClick={onClick}
		>
			{title || "Next"}
		</Button>
	);
};
