import { Typography, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
	secondaryBackground: {
		backgroundColor: theme.palette.secondary.main,
		"&:hover": {
			backgroundColor: theme.palette.secondary.dark,
		},
	},
	secondaryBorder: {
		color: theme.palette.secondary.main,
		borderColor: theme.palette.secondary.main,
		border: "2px solid",
		backgroundColor: "transparent",
		"&:hover": {
			color: theme.palette.secondary.dark,
			borderColor: theme.palette.secondary.dark,
		},
	},
	higlightBackground: {
		backgroundColor: theme.palette.third.main,
		"&:hover": {
			backgroundColor: theme.palette.third.dark,
		},
	},
	errorBorder: {
		color: theme.palette.error.main,
		borderColor: theme.palette.error.main,
		border: "2px solid",
		backgroundColor: "transparent",
		"&:hover": {
			color: theme.palette.error.dark,
			borderColor: theme.palette.error.dark,
		},
		"&:disabled": {
			color: theme.palette.grey.dark,
			borderColor: theme.palette.grey.dark,
			cursor: "not-allowed",
		},
	},
}));

export const PrimaryBackgroundButton = ({
	id = "primary-background-button",
	type = "button",
	disabled = false,
	className = "",
	titleClassName = "",
	titleColor = "white",
	size = "",
	width = "200px",
	title = "Button",
	onClick,
}) => (
	<Button
		key={id}
		id={id}
		type={type}
		disabled={disabled}
		className={className}
		variant="contained"
		color="primary"
		size={(size || "")}
		style={{ ...(width && { width }) }}
		onClick={onClick}
	>
		<Typography className={titleClassName} sx={{ color: `${titleColor}!important` }} style={{ textTransform: "none" }}>
			<b>
				{title}
			</b>
		</Typography>
	</Button>
);

export const PrimaryBorderButton = ({
	id = "primary-border-button",
	type = "button",
	disabled = false,
	className = "",
	titleClassName = "",
	titleColor = "primary",
	size = "",
	width = "200px",
	title = "Button",
	backgroundColor = "white",
	onClick,
}) => (
	<Button
		key={id}
		id={id}
		type={type}
		disabled={disabled}
		className={className}
		variant="outlined"
		color="primary"
		size={(size || "")}
		style={{ ...(width && { width }), backgroundColor: (backgroundColor || "white"), borderWidth: "3px", borderColor: titleColor }}
		onClick={onClick}
	>
		<Typography className={titleClassName} sx={{ color: `${titleColor}!important` }} style={{ textTransform: "none" }}>
			<b>
				{title}
			</b>
		</Typography>
	</Button>
);

export const SecondaryBackgroundButton = ({
	id = "secondary-background-button",
	type = "button",
	disabled = false,
	className = "",
	titleClassName = "",
	titleColor = "white",
	size = "",
	width = "200px",
	title = "Button",
	onClick,
	...rest
}) => {
	const classes = useStyles();
	return (
		<Button
			key={id}
			id={id}
			type={type}
			disabled={disabled}
			className={`${classes.secondaryBackground} ${className}`}
			size={(size || "")}
			style={{ ...(width && { width }) }}
			onClick={onClick}
			{...rest}
		>
			<Typography className={titleClassName} sx={{ color: `${titleColor}!important` }} style={{ textTransform: "none" }}>
				<b>
					{title}
				</b>
			</Typography>
		</Button>
	);
};

export const SecondaryBorderButton = ({
	id = "secondary-border-button",
	type = "button",
	disabled = false,
	className = "",
	titleClassName = "",
	titleColor = "secondary",
	size = "",
	width = "200px",
	title = "Button",
	onClick,
	...rest
}) => {
	const classes = useStyles();
	return (
		<Button
			key={id}
			id={id}
			type={type}
			disabled={disabled}
			className={`${classes.secondaryBorder} ${className}`}
			size={(size || "")}
			style={{ ...(width && { width }) }}
			onClick={onClick}
			{...rest}
		>
			<Typography className={titleClassName} sx={{ color: `${titleColor}!important` }} style={{ textTransform: "none" }}>
				<b>
					{title}
				</b>
			</Typography>
		</Button>
	);
};

export const HighlightBackgroundButton = ({
	id = "highlight-background-button",
	type = "button",
	disabled = false,
	className = "",
	titleClassName = "",
	titleColor = "white",
	size = "",
	width = "200px",
	title = "Button",
	onClick,
}) => {
	const classes = useStyles();
	return (
		<Button
			key={id}
			id={id}
			type={type}
			disabled={disabled}
			className={`${classes.higlightBackground} ${className}`}
			size={(size || "")}
			style={{ ...(width && { width }) }}
			onClick={onClick}
		>
			<Typography className={titleClassName} sx={{ color: `${titleColor}!important` }} style={{ textTransform: "none" }}>
				<b>
					{title}
				</b>
			</Typography>
		</Button>
	);
};

export const HighlightBorderButton = ({
	id = "highlight-border-button",
	type = "button",
	disabled = false,
	className = "",
	titleClassName = "",
	titleColor = "third",
	size = "",
	width = "200px",
	title = "Button",
	backgroundColor = "white",
	onClick,
}) => (
	<Button
		key={id}
		id={id}
		type={type}
		disabled={disabled}
		className={className}
		variant="outlined"
		color="third"
		size={(size || "")}
		style={{ ...(width && { width }), backgroundColor: (backgroundColor || "white"), borderWidth: "3px" }}
		onClick={onClick}
	>
		<Typography className={titleClassName} sx={{ color: `${titleColor}!important` }} style={{ textTransform: "none" }}>
			<b>
				{title}
			</b>
		</Typography>
	</Button>
);

export const SuccessBackgroundButton = ({
	id = "success-background-button",
	type = "button",
	disabled = false,
	className = "",
	titleClassName = "",
	titleColor = "white",
	size = "",
	width = "200px",
	title = "Button",
	onClick,
}) => (
	<Button
		key={id}
		id={id}
		type={type}
		disabled={disabled}
		className={className}
		variant="contained"
		color="success"
		size={(size || "")}
		style={{ ...(width && { width }) }}
		onClick={onClick}
	>
		<Typography className={titleClassName} sx={{ color: `${titleColor}!important` }} style={{ textTransform: "none" }}>
			<b>
				{title}
			</b>
		</Typography>
	</Button>
);

export const ErrorBackgroundButton = ({
	id = "error-background-button",
	type = "button",
	disabled = false,
	className = "",
	titleClassName = "",
	titleColor = "white",
	size = "",
	width = "200px",
	title = "Button",
	onClick,
}) => (
	<Button
		key={id}
		id={id}
		type={type}
		disabled={disabled}
		className={className}
		variant="contained"
		color="error"
		size={(size || "")}
		style={{ ...(width && { width }) }}
		onClick={onClick}
	>
		<Typography className={titleClassName} sx={{ color: `${titleColor}!important` }} style={{ textTransform: "none" }}>
			<b>
				{title}
			</b>
		</Typography>
	</Button>
);

export const ErrorBorderButton = ({
	id = "error-border-button",
	type = "button",
	disabled = false,
	className = "",
	titleClassName = "",
	size = "",
	width = "200px",
	title = "Button",
	onClick,
	...rest
}) => {
	const classes = useStyles();
	return (
		<Button
			key={id}
			id={id}
			type={type}
			disabled={disabled}
			className={`${classes.errorBorder} ${className}`}
			size={(size || "")}
			style={{ ...(width && { width }) }}
			onClick={onClick}
			{...rest}
		>
			<Typography className={titleClassName} style={{ textTransform: "none" }}>
				<b>
					{title}
				</b>
			</Typography>
		</Button>
	);
};

export const InfoBackgroundButton = ({
	id = "info-background-button",
	type = "button",
	disabled = false,
	className = "",
	titleClassName = "",
	titleColor = "white",
	size = "",
	width = "200px",
	title = "Button",
	onClick,
}) => (
	<Button
		key={id}
		id={id}
		type={type}
		disabled={disabled}
		className={className}
		variant="contained"
		color="info"
		size={(size || "")}
		style={{ ...(width && { width }) }}
		onClick={onClick}
	>
		<Typography className={titleClassName} sx={{ color: `${titleColor}!important` }} style={{ textTransform: "none" }}>
			<b>
				{title}
			</b>
		</Typography>
	</Button>
);
