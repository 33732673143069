import { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { Image } from "mui-image";
import { makeStyles } from "@mui/styles";

import { ErrorBorderButton, SecondaryBackgroundButton, SecondaryBorderButton } from "../../../Buttons.js";
import Draggable from "react-draggable";
import jwt from "../../../../utils/jwt.js";

const { REACT_APP_MAIN_SERVER_URL } = process.env;

const useStyles = makeStyles((theme) => ({
	root: {
		width: "100%",
		height: "100%",
		display: "flex",
		flexDirection: "column",
	},
	menuBar: {
		width: "100%",
		height: "50px",
		backgroundColor: theme.palette.grey.dark,
	},
	mainRow: {
		width: "100%",
		height: "calc(100% - 50px)",
		display: "flex",
	},
	mapArea: {
		height: "100%",
		minHeight: "400px",
		backgroundColor: "blue",
	},
	menuArea: {
		height: "100%",
		display: "flex",
	},
	mainMap: {
		width: "100%",
		height: "100%",
		display: "flex",
		alignItems: "center",
		backgroundColor: theme.palette.primary.dark,
	},
	map: {
		backgroundColor: theme.palette.white.main,
		position: "relative",
		margin: "auto",
	},
	box: {
		display: "flex",
		borderColor: `${theme.palette.grey.main}!important`,
		position: "absolute",
		cursor: "move",
		justifyContent: "center",
	},
}));

const getMapDimensions = (mainMapWidth, mainMapHeight) => {
	const availWidth = mainMapWidth;
	const availHeight = mainMapHeight;
	const initRatio = mainMapWidth / mainMapHeight;
	const ratio = 1.5;

	if (initRatio === ratio) {
		return { width: availWidth, height: availHeight };
	}

	if (initRatio > ratio) {
		return { width: ratio * availHeight, height: availHeight };
	}

	return { width: availWidth, height: availWidth / ratio };
};

const getElementDimension = (mapWidth, mapHeight, nRows, nCols) => {
	const elementWidth = (mapWidth) / nCols;
	const elementHeight = (mapHeight) / nRows;
	return Math.min(elementWidth, elementHeight);
};

const PlaceObjects = ({
	gridMeters: propsGridMeters,
	mapWidth: propsMapWidth,
	mapHeight: propsMapHeight,
	mapWidthMeters: propsMapWidthMeters,
	mode: propsMode,
	boxes: propsBoxes,
	numberOfRows: propsNumberOfRows,
	numberOfColumns: propsNumberOfColumns,
	mapImage: propsMapImage,
	setGridDimension: propsSetGridDimension,
	setMapDimensions: propsSetMapDimensions,
}) => {
	const classes = useStyles();

	const [mapWidth, setMapWidth] = useState(propsMapWidth);
	const [mapHeight, setMapHeight] = useState(propsMapHeight);
	const [mapWidthMeters, setMapWidthMeters] = useState(propsMapWidthMeters);
	const [gridMeters, setGridMeters] = useState(propsGridMeters);

	const [mode, setMode] = useState(propsMode);
	const [boxes, setBoxes] = useState(propsBoxes);
	const [numberOfRows, setNumberOfRows] = useState(propsNumberOfRows);
	const [numberOfColumns, setNumberOfColumns] = useState(propsNumberOfColumns);
	const [mapImage, setMapImage] = useState(propsMapImage);

	useEffect(() => {
		setMapWidth(propsMapWidth);
	}, [propsMapWidth]);

	useEffect(() => {
		setMapHeight(propsMapHeight);
	}, [propsMapHeight]);

	useEffect(() => {
		setMapWidthMeters(propsMapWidthMeters);
	}, [propsMapWidthMeters]);

	useEffect(() => {
		setGridMeters(propsGridMeters);
	}, [propsGridMeters]);

	useEffect(() => {
		setBoxes(propsBoxes);
	}, [propsBoxes]);

	useEffect(() => {
		setNumberOfRows(propsNumberOfRows);
	}, [propsNumberOfRows]);

	useEffect(() => {
		setNumberOfColumns(propsNumberOfColumns);
	}, [propsNumberOfColumns]);

	useEffect(() => {
		setMode(propsMode);
	}, [propsMode]);

	useEffect(() => {
		setMapImage(propsMapImage);
	}, [propsMapImage]);

	useEffect(() => {
		const changeMapDimensions = () => {
			const mainMapWidth = document.querySelector('#place-objects-main-map').offsetWidth;
			const mainMapHeight = document.querySelector('#place-objects-main-map').offsetHeight;
			const dimensions = getMapDimensions(mainMapWidth, mainMapHeight);
			const gridDimension = (gridMeters * dimensions.width) / mapWidthMeters;
			propsSetMapDimensions(dimensions.width, dimensions.height);
			propsSetGridDimension(gridDimension);
		};

		const mainMapWidth = document.querySelector("#place-objects-main-map").offsetWidth;
		const mainMapHeight = document.querySelector("#place-objects-main-map").offsetHeight;
		const dimensions = getMapDimensions(mainMapWidth, mainMapHeight);
		propsSetMapDimensions(dimensions.width, dimensions.height, true);
		window.addEventListener("resize", changeMapDimensions);

		return () => {
			window.removeEventListener("resize", changeMapDimensions);
		};
	}, [boxes, gridMeters, mapWidthMeters, numberOfColumns, numberOfRows, propsSetGridDimension, propsSetMapDimensions]);

	const elementDimension = getElementDimension(mapWidth, mapHeight, numberOfRows, numberOfColumns);
	const grid = [];
	for (const box of Object.keys(boxes)) {
		grid.push(
			<Draggable
				key={box}
				disabled
				allowAnyClick={false}
				position={{ x: 0, y: 0 }}
			>
				<div
					id={box}
					className={classes.box}
					style={{
						width: elementDimension,
						height: elementDimension,
						border: "none",
						left: ((mapWidth - numberOfColumns * elementDimension) / 2 + (boxes[box].column - 1) * elementDimension),
						top: ((mapHeight - numberOfRows * elementDimension) / 2 + (boxes[box].row - 1) * elementDimension),
					}}
				>
					<Image
						src={boxes[box].content}
						alt=""
						style={{
							maxWidth: "100%",
							maxHeight: "100%",
							transform: `rotate(${boxes[box].rotation}deg)`,
						}}
					/>
				</div>
			</Draggable>,
		);
	}

	return (
		<Grid container className={classes.root}>
			<Grid item className={classes.menuBar} />
			<Grid item className={classes.mainRow} sx={{ flexDirection: { md: "row", xs: "column" } }}>
				<Grid item md={9} lg={10} className={classes.mapArea}>
					<Grid container id="place-objects-main-map" className={classes.mainMap}>
						<Grid item id="place-objects-map" className={classes.map} sx={{ width: `${mapWidth}px`, height: `${mapHeight}px` }}>
							<Grid item width="100%" height="100%" sx={{ opacity: 0.6 }}>
								{mode === "create" && (
									grid
								)}
								{mode === "upload" && (
									<Grid item width="100%" height="100%" display="flex" justifyContent="center" alignItems="center">
										<Image src={`${REACT_APP_MAIN_SERVER_URL}/uploads/${mapImage}?token=${jwt.getToken()}`} alt="Upload" />
									</Grid>
								)}
							</Grid>
						</Grid>
					</Grid>
				</Grid>
				<Grid
					item
					md={3}
					lg={2}
					className={classes.menuArea}
					sx={{
						justifyContent: { md: "center", xs: "space-evenly" },
						alignItems: { md: "center", xs: "flex-start" },
						flexDirection: { md: "column", xs: "row" },
						flexWrap: { md: "nowrap", xs: "wrap" },
						marginTop: { md: 0, xs: 5 },
					}}
				>
					{/* <SecondaryBackgroundButton
						title="Create Map"
						width="150px"
						sx={{
							marginTop: { md: 0, xs: 0 },
							marginLeft: { md: 0, xs: 0 },
						}}
						onClick={() => {}}
					/>
					<SecondaryBorderButton
						title="Upload Image"
						width="150px"
						sx={{
							marginTop: { md: 4, xs: 0 },
							marginLeft: { md: 0, xs: 2 },
						}}
						onClick={() => {}}
					/>
					<ErrorBorderButton
						title="Clear Map"
						width="150px"
						sx={{
							marginTop: { md: 4, xs: 0 },
							marginLeft: { md: 0, xs: 2 },
						}}
						onClick={() => {}}
					/> */}
				</Grid>
			</Grid>
		</Grid>
	);
};

export default PlaceObjects;
