import { Fragment, useEffect, useMemo, useState } from "react";
import { Button, Collapse, Grid, Typography } from "@mui/material";
import { Image } from "mui-image";
import { makeStyles } from "@mui/styles";
import Draggable from "react-draggable";

import { ErrorBorderButton, SecondaryBackgroundButton, SecondaryBorderButton } from "../../../Buttons.js";
import categories from "./categories/index.js";
import FileUpload from "../../../FileUpload.js";
import jwt from "../../../../utils/jwt.js";
import Dialog from "../../../Dialog.js";
import Slider from "../../../Slider.js";
import Checkbox from "../../../Checkbox.js";

const { REACT_APP_MAIN_SERVER_URL } = process.env;

const useStyles = makeStyles((theme) => ({
	root: {
		width: "100%",
		height: "100%",
		display: "flex",
		flexDirection: "column",
	},
	menuBar: {
		width: "100%",
		height: "50px",
		display: "flex",
		flexDirection: "row",
	},
	mainRow: {
		width: "100%",
		height: "calc(100% - 50px)",
		display: "flex",
	},
	mapArea: {
		height: "100%",
		minHeight: "400px",
	},
	menuArea: {
		height: "100%",
		display: "flex",
		overflow: "auto",
	},
	mainMap: {
		width: "100%",
		height: "100%",
		display: "flex",
		alignItems: "center",
		backgroundColor: theme.palette.primary.dark,
	},
	map: {
		backgroundColor: theme.palette.white.main,
		position: "relative",
		margin: "auto",
	},
	box: {
		display: "flex",
		outlineColor: `${theme.palette.grey.main}!important`,
		position: "absolute",
		cursor: "move",
		justifyContent: "center",
	},
	draggedItem: {
		width: "80px",
		height: "80px",
		opacity: 0.6,
		position: "fixed",
		cursor: "move",
		zIndex: 100,
	},
	slider: {
		width: "200px",
		marginLeft: "20px",
	},
	sliderBox: {
		color: theme.palette.secondary.main,
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
	},
}));

const getMapDimensions = (mainMapWidth, mainMapHeight) => {
	const availWidth = mainMapWidth;
	const availHeight = mainMapHeight;
	const initRatio = mainMapWidth / mainMapHeight;
	const ratio = 1.5;

	if (initRatio === ratio) {
		return { width: availWidth, height: availHeight };
	}

	if (initRatio > ratio) {
		return { width: ratio * availHeight, height: availHeight };
	}

	return { width: availWidth, height: availWidth / ratio };
};

const toRadians = (angle) => (
	angle * (Math.PI / 180)
);

const transformWall = (wall, boxPos, rotation, mapPos, nextId) => {
	const x1Box = boxPos.width * (wall.x1 / 100);
	const y1Box = boxPos.height * (wall.y1 / 100);
	const newX1Box = (x1Box - (boxPos.width / 2)) * Math.cos(toRadians(-rotation))
		- (y1Box - (boxPos.height / 2)) * Math.sin(toRadians(-rotation)) + (boxPos.width / 2);
	const newY1Box = (x1Box - (boxPos.width / 2)) * Math.sin(toRadians(-rotation))
		+ (y1Box - (boxPos.height / 2)) * Math.cos(toRadians(-rotation)) + (boxPos.height / 2);
	const x1Px = newX1Box + boxPos.left - mapPos.left;
	const x1 = (x1Px / mapPos.width) * 100;
	const y1Px = newY1Box - boxPos.bottom + mapPos.bottom;
	const y1 = (y1Px / mapPos.height) * 100;
	const x2Box = boxPos.width * (wall.x2 / 100);
	const y2Box = boxPos.height * (wall.y2 / 100);
	const newX2Box = (x2Box - (boxPos.width / 2)) * Math.cos(toRadians(-rotation))
		- (y2Box - (boxPos.height / 2)) * Math.sin(toRadians(-rotation)) + (boxPos.width / 2);
	const newY2Box = (x2Box - (boxPos.width / 2)) * Math.sin(toRadians(-rotation))
		+ (y2Box - (boxPos.height / 2)) * Math.cos(toRadians(-rotation)) + (boxPos.height / 2);
	const x2Px = newX2Box + boxPos.left - mapPos.left;
	const x2 = (x2Px / mapPos.width) * 100;
	const y2Px = newY2Box - boxPos.bottom + mapPos.bottom;
	const y2 = (y2Px / mapPos.height) * 100;
	return {
		x1, y1, x2, y2, id: nextId,
	};
};

const transformWater = (water, boxPos, rotation, mapPos, nextId) => {
	const xBox = boxPos.width * (water.x / 100);
	const x2Box = boxPos.width * ((water.x + water.range) / 100);
	const yBox = boxPos.height * (water.y / 100);
	const newXBox = (xBox - (boxPos.width / 2)) * Math.cos(toRadians(-rotation))
		- (yBox - (boxPos.height / 2)) * Math.sin(toRadians(-rotation)) + (boxPos.width / 2);
	const newYBox = (xBox - (boxPos.width / 2)) * Math.sin(toRadians(-rotation))
		+ (yBox - (boxPos.height / 2)) * Math.cos(toRadians(-rotation)) + (boxPos.height / 2);
	const newX2Box = (x2Box - (boxPos.width / 2)) * Math.cos(toRadians(-rotation))
		- (yBox - (boxPos.height / 2)) * Math.sin(toRadians(-rotation)) + (boxPos.width / 2);
	const newY2Box = (x2Box - (boxPos.width / 2)) * Math.sin(toRadians(-rotation))
		+ (yBox - (boxPos.height / 2)) * Math.cos(toRadians(-rotation)) + (boxPos.height / 2);
	const xPx = newXBox + boxPos.left - mapPos.left;
	const x = (xPx / mapPos.width) * 100;
	const yPx = newYBox - boxPos.bottom + mapPos.bottom;
	const y = (yPx / mapPos.height) * 100;
	const x2Px = newX2Box + boxPos.left - mapPos.left;
	const x2 = (x2Px / mapPos.width) * 100;
	const y2Px = newY2Box - boxPos.bottom + mapPos.bottom;
	const y2 = (y2Px / mapPos.height) * 100;
	return {
		x, y, x2, y2, id: nextId,
	};
};

const CreateMap = ({
	gridMeters: propsGridMeters,
	mapWidth: propsMapWidth,
	mapHeight: propsMapHeight,
	mapWidthMeters: propsMapWidthMeters,
	mode: propsMode,
	boxes: propsBoxes,
	numberOfRows: propsNumberOfRows,
	numberOfColumns: propsNumberOfColumns,
	mapImage: propsMapImage,
	setGridDimension: propsSetGridDimension,
	setMapDimensions: propsSetMapDimensions,
	setMode: propsSetMode,
	setBoxes: propsSetBoxes,
	setNumberOfRows: propsSetNumberOfRows,
	setNumberOfColumns: propsSetNumberOfColumns,
	setMapImage: propsSetMapImage,
}) => {
	const classes = useStyles();

	const [mapWidth, setMapWidth] = useState(propsMapWidth);
	const [mapHeight, setMapHeight] = useState(propsMapHeight);
	const [mapWidthMeters, setMapWidthMeters] = useState(propsMapWidthMeters);
	const [gridMeters, setGridMeters] = useState(propsGridMeters);

	const [mode, setMode] = useState(propsMode);
	const [displayMode, setDisplayMode] = useState(null);
	const [boxes, setBoxes] = useState(propsBoxes);
	const [numberOfRows, setNumberOfRows] = useState(propsNumberOfRows);
	const [numberOfColumns, setNumberOfColumns] = useState(propsNumberOfColumns);
	const [gridVisible, setGridVisible] = useState(true);
	const [mapImage, setMapImage] = useState(propsMapImage);
	const elementDimension = useMemo(() => {
		const elementWidth = (mapWidth) / numberOfColumns;
		const elementHeight = (mapHeight) / numberOfRows;
		return Math.min(elementWidth, elementHeight);
	}, [mapHeight, mapWidth, numberOfColumns, numberOfRows]);

	const [openCategory, setOpenCategory] = useState(null);
	const [compDragging, setCompDragging] = useState(null);
	const [dragging, setDragging] = useState(null);
	const [draggingCategory, setDraggingCategory] = useState(null);
	const [draggingPosition, setDraggingPosition] = useState({ x: 0, y: 0 });

	useEffect(() => {
		setMapWidth(propsMapWidth);
	}, [propsMapWidth]);

	useEffect(() => {
		setMapHeight(propsMapHeight);
	}, [propsMapHeight]);

	useEffect(() => {
		setMapWidthMeters(propsMapWidthMeters);
	}, [propsMapWidthMeters]);

	useEffect(() => {
		setGridMeters(propsGridMeters);
	}, [propsGridMeters]);

	useEffect(() => {
		setBoxes(propsBoxes);
	}, [propsBoxes]);

	useEffect(() => {
		setNumberOfRows(propsNumberOfRows);
	}, [propsNumberOfRows]);

	useEffect(() => {
		setNumberOfColumns(propsNumberOfColumns);
	}, [propsNumberOfColumns]);

	useEffect(() => {
		setMode(propsMode);
	}, [propsMode]);

	useEffect(() => {
		setMapImage(propsMapImage);
	}, [propsMapImage]);

	const changeMapDimensions = () => {
		const mainMapWidth = document.querySelector("#create-map-main-map").offsetWidth;
		const mainMapHeight = document.querySelector("#create-map-main-map").offsetHeight;
		const dimensions = getMapDimensions(mainMapWidth, mainMapHeight);
		const gridDimension = (gridMeters * dimensions.width) / mapWidthMeters;
		propsSetMapDimensions(dimensions.width, dimensions.height);
		propsSetGridDimension(gridDimension);
	};

	useEffect(() => {
		const mainMapWidth = document.querySelector("#create-map-main-map").offsetWidth;
		const mainMapHeight = document.querySelector("#create-map-main-map").offsetHeight;
		const dimensions = getMapDimensions(mainMapWidth, mainMapHeight);
		propsSetMapDimensions(dimensions.width, dimensions.height, true);
		window.addEventListener("resize", changeMapDimensions);

		const tempBoxes = boxes;
		for (let i = 0; i < numberOfRows; i++) {
			for (let j = 0; j < numberOfColumns; j++) {
				if (!(`${i + 1}-${j + 1}` in tempBoxes)) {
					tempBoxes[`${i + 1}-${j + 1}`] = {
						row: i + 1,
						column: j + 1,
						content: null,
						contentId: null,
						contentCategory: null,
						rotation: 0,
					};
				}
			}
		}

		propsSetBoxes(tempBoxes);

		return () => {
			window.removeEventListener("resize", changeMapDimensions);
		};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		changeMapDimensions();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [displayMode]);

	const changeNumberOfRows = (value) => {
		const tempBoxes = { ...boxes };
		if (numberOfRows > value) {
			for (let i = numberOfRows; i > value; i--) {
				for (let j = 0; j < numberOfColumns; j++) {
					delete tempBoxes[`${i}-${j + 1}`];
				}
			}
		} else {
			for (let i = numberOfRows; i < value; i++) {
				for (let j = 0; j < numberOfColumns; j++) {
					if (!(`${i + 1}-${j + 1}` in tempBoxes)) {
						tempBoxes[`${i + 1}-${j + 1}`] = {
							row: i + 1,
							column: j + 1,
							content: null,
							contentId: null,
							contentCategory: null,
							rotation: 0,
						};
					}
				}
			}
		}

		propsSetBoxes(tempBoxes);
		propsSetNumberOfRows(value);
	};

	const changeNumberOfColumns = (value) => {
		const tempBoxes = { ...boxes };
		if (numberOfColumns > value) {
			for (let i = 0; i < numberOfRows; i++) {
				for (let j = numberOfColumns; j > value; j--) {
					delete tempBoxes[`${i + 1}-${j}`];
				}
			}
		} else {
			for (let i = 0; i < numberOfRows; i++) {
				for (let j = numberOfColumns; j < value; j++) {
					if (!(`${i + 1}-${j + 1}` in tempBoxes)) {
						tempBoxes[`${i + 1}-${j + 1}`] = {
							row: i + 1,
							column: j + 1,
							content: null,
							contentId: null,
							contentCategory: null,
							rotation: 0,
						};
					}
				}
			}
		}

		propsSetBoxes(tempBoxes);
		propsSetNumberOfColumns(value);
	};

	const gridClicked = (boxIndex) => {
		const tempBoxes = { ...boxes };
		console.log(tempBoxes);
		console.log(boxIndex);
		console.log(tempBoxes[boxIndex].rotation);
		tempBoxes[boxIndex].rotation = (tempBoxes[boxIndex].rotation + 90) % 360;
		propsSetBoxes(tempBoxes);
		propsSetMode("create");
	};

	const changeCategoryDialog = (category) => {
		if (openCategory === category) {
			setOpenCategory(null);
		} else {
			setOpenCategory(category);
		}
	};

	// eslint-disable-next-line unicorn/consistent-function-scoping
	const onCompStart = (event) => {
		event.preventDefault();
	};

	const onCompDrag = (event, comp) => {
		event.preventDefault();
		setCompDragging(comp);
	};

	const onCompStop = (event, bbox) => {
		event.preventDefault();
		console.log(compDragging);

		if (compDragging === null) {
			gridClicked(bbox);
			return;
		}

		const tempBoxes = { ...boxes };

		const { bottom, left, top, right } = document.querySelector("#create-map-map").getBoundingClientRect();
		const { x, y } = event;
		if ((x >= left) && (x <= right) && (y <= bottom) && (y >= top)) {
			for (const box of Object.keys(tempBoxes)) {
				const comp = document.querySelector(`#box${box}`).getBoundingClientRect();
				if ((x >= comp.left) && (x <= comp.right) && (y <= comp.bottom) && (y >= comp.top) && box !== compDragging) {
					tempBoxes[box].content = tempBoxes[compDragging].content;
					tempBoxes[box].contentId = tempBoxes[compDragging].contentId;
					tempBoxes[box].contentCategory = tempBoxes[compDragging].contentCategory;
					tempBoxes[box].rotation = tempBoxes[compDragging].rotation;
				}
			}
		}

		delete tempBoxes[compDragging];
		tempBoxes[compDragging] = {
			row: boxes[bbox].row,
			column: boxes[bbox].column,
			content: null,
			contentId: null,
			contentCategory: null,
			rotation: 0,
		};

		setCompDragging(null);
		propsSetBoxes(tempBoxes);
		propsSetMode("create");
	};

	// eslint-disable-next-line unicorn/consistent-function-scoping
	const onStart = (event) => {
		event.preventDefault();
	};

	const onDrag = (event, comp, cat) => {
		event.preventDefault();
		setDragging(comp);
		setDraggingCategory(cat);
		setDraggingPosition({ x: event.pageX, y: event.pageY });
	};

	const onStop = (event) => {
		event.preventDefault();

		const { bottom, left, top, right } = document.querySelector("#create-map-map").getBoundingClientRect();
		const { x, y } = event;
		if ((x >= left) && (x <= right) && (y <= bottom) && (y >= top)) {
			for (const box of Object.keys(boxes)) {
				const comp = document.querySelector(`#box${box}`).getBoundingClientRect();
				if ((x >= comp.left) && (x <= comp.right) && (y <= comp.bottom) && (y >= comp.top)) {
					console.log("FOUND");
					console.log(boxes[box]);
					boxes[box].content = categories[draggingCategory].components[dragging].image;
					boxes[box].contentId = categories[draggingCategory].components[dragging].id;
					boxes[box].contentCategory = draggingCategory;
					boxes[box].rotation = 0;
				}
			}
		}

		setDragging(null);
		setDraggingCategory(null);
		propsSetBoxes(boxes);
		propsSetMode("create");
	};

	const grid = [];
	for (const box of Object.keys(boxes)) {
		grid.push(
			<Draggable
				key={box}
				allowAnyClick={false}
				position={{ x: 0, y: 0 }}
				onStart={onCompStart}
				onDrag={(event) => { onCompDrag(event, box); }}
				onStop={(event) => { onCompStop(event, box); }}
			>
				<div
					id={`box${box}`}
					className={classes.box}
					style={{
						width: elementDimension,
						height: elementDimension,
						outline: (gridVisible ? "1px solid" : "none"),
						left: ((mapWidth - numberOfColumns * elementDimension) / 2 + (boxes[box].column - 1) * elementDimension),
						top: ((mapHeight - numberOfRows * elementDimension) / 2 + (boxes[box].row - 1) * elementDimension),
					}}
				>
					<Image
						src={boxes[box].content}
						alt=""
						duration={500}
						style={{
							maxWidth: "100%",
							maxHeight: "100%",
							transform: `rotate(${boxes[box].rotation}deg)`,
						}}
					/>
				</div>
			</Draggable>,
		);
	}

	return (
		<Grid container className={classes.root}>
			{displayMode === "create" && (
				<Grid item className={classes.menuBar}>
					{displayMode === "create" && (
						<>
							<Grid item className={classes.sliderBox}>
								{"Rows"}
								<Slider
									marks
									value={numberOfRows}
									min={3}
									max={10}
									step={1}
									className={classes.slider}
									onChange={(__, value) => { changeNumberOfRows(value); }}
								/>
							</Grid>
							<Grid item className={classes.sliderBox} ml={1}>
								{"Columns"}
								<Slider
									marks
									value={numberOfColumns}
									min={3}
									max={15}
									step={1}
									className={classes.slider}
									onChange={(__, value) => { changeNumberOfColumns(value); }}
								/>
							</Grid>
							<Grid item className={classes.sliderBox} ml={1}>
								{`(${numberOfRows} x ${numberOfColumns})`}
							</Grid>
							<Grid item className={classes.sliderBox} ml={2}>
								{"Grid"}
								<Checkbox
									checked={gridVisible}
									onChange={() => { setGridVisible(!gridVisible); }}
								/>
							</Grid>
						</>
					)}
				</Grid>
			)}
			<Grid item className={classes.mainRow} sx={{ flexDirection: { md: "row", xs: "column" } }} style={{ height: (displayMode === "create") ? "calc(100% - 50px)" : "100%" }}>
				<Grid item md={9} lg={10} className={classes.mapArea}>
					<Grid container id="create-map-main-map" className={classes.mainMap}>
						<Grid item id="create-map-map" className={classes.map} sx={{ width: `${mapWidth}px`, height: `${mapHeight}px` }}>
							{(displayMode === "create" || (!displayMode && mode === "create")) && (
								grid
							)}
							{(displayMode === "upload" || (!displayMode && mode === "upload")) && (
								<Grid item width="100%" height="100%" display="flex" justifyContent="center" alignItems="center">
									<Image src={`${REACT_APP_MAIN_SERVER_URL}/uploads/${mapImage}?token=${jwt.getToken()}`} alt="Upload" />
								</Grid>
							)}
						</Grid>
					</Grid>
					<div
						className={classes.draggedItem}
						style={{
							display: ((dragging === null) ? "none" : "flex"),
							left: `${draggingPosition.x - 40}px`,
							top: `${draggingPosition.y - 40}px`,
						}}
					>
						{dragging !== null
							&& <img src={categories[draggingCategory].components[dragging].image} alt="" style={{ maxWidth: "100%", maxHeight: "100%" }} />}
					</div>
				</Grid>
				<Grid
					item
					md={3}
					lg={2}
					className={classes.menuArea}
					sx={{
						justifyContent: { md: "center", xs: "space-evenly" },
						alignItems: { md: "center", xs: "flex-start" },
						flexDirection: { md: "column", xs: "row" },
						flexWrap: { md: "nowrap", xs: "wrap" },
						marginTop: { md: 0, xs: 5 },
					}}
				>
					{!displayMode && (
						<>
							<SecondaryBackgroundButton
								title="Create Map"
								width="150px"
								sx={{
									marginTop: { md: 0, xs: 0 },
									marginLeft: { md: 0, xs: 0 },
								}}
								onClick={() => {
									setDisplayMode("create");
								}}
							/>
							<SecondaryBorderButton
								title="Upload Image"
								width="150px"
								sx={{
									marginTop: { md: 4, xs: 0 },
									marginLeft: { md: 0, xs: 2 },
								}}
								onClick={() => {
									setDisplayMode("upload");
								}}
							/>
							<ErrorBorderButton
								disabled={!mode}
								title="Clear Map"
								width="150px"
								sx={{
									marginTop: { md: 4, xs: 0 },
									marginLeft: { md: 0, xs: 2 },
								}}
								onClick={() => {
									setDisplayMode("clear");
								}}
							/>
						</>
					)}
					{displayMode === "create" && (
						<>
							{Object.keys(categories).map((cat) => (
								<span key={cat} style={{ minHeight: "auto", textAlign: "center" }}>
									<Button
										key={`${cat}_int`}
										color="secondary"
										variant="contained"
										style={{
											width: "150px",
											marginBottom: "10px",
											marginTop: "10px",
										}}
										id={cat}
										onClick={() => { changeCategoryDialog(cat); }}
									>
										{categories[cat].name}
									</Button>
									<Collapse key={`${cat}_collapse`} in={openCategory === cat}>
										<div
											style={{
												background: "transparent",
												width: "100%",
												display: "flex",
												flexWrap: "wrap",
												justifyContent: "space-evenly",
												marginTop: "2px",
												minHeight: "auto",
											}}
										>
											{Object.keys(categories[cat].components).map((comp) => (
												<Fragment key={`${cat}_${comp}_fragment`}>
													<Draggable
														allowAnyClick={false}
														position={{ x: 0, y: 0 }}
														onStart={onStart}
														onDrag={(event) => { onDrag(event, comp, cat); }}
														onStop={onStop}
													>
														<div
															style={{
																display: (dragging === comp) ? "none" : "flex",
																width: "70px",
																height: "70px",
																cursor: "move",
																zIndex: 1,
																justifyContent: "center",
																margin: "5px",
															}}
														>
															<img src={categories[cat].components[comp].image} alt={comp} draggable={false} style={{ maxWidth: "100%", maxHeight: "100%" }} />
														</div>
													</Draggable>
													<div style={{ display: (dragging === comp) ? "flex" : "none", width: "70px", height: "70px", cursor: "move", zIndex: 1, justifyContent: "center", margin: "5px" }} />
												</Fragment>
											))}
										</div>
									</Collapse>
								</span>
							))}
							<SecondaryBorderButton
								title="Back"
								width="150px"
								sx={{
									marginTop: { md: 4, xs: 0 },
									marginLeft: { md: 0, xs: 2 },
								}}
								onClick={() => {
									setDisplayMode(null);
								}}
							/>
						</>
					)}
					{displayMode === "upload" && (
						<>
							<FileUpload
								id="create-map-upload"
								width="150px"
								onSuccess={(response) => {
									propsSetMapImage(response.saveName);
									propsSetMode("upload");
								}}
							/>
							<Typography
								width="150px"
								color="error"
								variant="body1"
								sx={{
									marginTop: { md: 1, xs: 0 },
									marginLeft: { md: 0, xs: 2 },
								}}
							>
								{"Attention: The image aspect ratio should be 3:2."}
							</Typography>
							<SecondaryBorderButton
								title="Back"
								width="150px"
								sx={{
									marginTop: { md: 4, xs: 0 },
									marginLeft: { md: 0, xs: 2 },
								}}
								onClick={() => {
									setDisplayMode(null);
								}}
							/>
						</>
					)}
					{displayMode === "clear" && (
						<Dialog
							open
							title="Clear Map?"
							text="Are you sure you want to clear the map? All the components will be removed."
							confirmButton="Clear"
							cancelButton="Cancel"
							onConfirm={() => {
								setDisplayMode(null);
								propsSetBoxes({});
								propsSetMapImage(null);
								propsSetMode(null);
							}}
							onClose={() => {
								setDisplayMode(null);
							}}
						/>
					)}
				</Grid>
			</Grid>
		</Grid>
	);
};

export default CreateMap;
